import React, { useEffect, useState } from 'react';
import swtichIcon from '../assets/icons/refresh-cw.svg';
import { Link } from 'react-router-dom';
import dollarIcon from '../assets/icons/dollarIcon.svg';
import hashIcon from '../assets/icons/hashIcon.svg';
import giftIconDash from '../assets/icons/giftIconDash.svg';
import conservationRate from '../assets/icons/conservationRate.svg';
import RecentEarning from '../inc/recent-earning';
import { Form } from 'react-bootstrap';
import SelectDropDownYTD from '../inc/selectDropDownYTD';
import { getEarningList } from '../../axiosCalls/seller';
import { affilateOverview } from '../../user-dashboard/pages/driver/constant';
import { driver } from 'driver.js';

const SellerOverview = () => {
    const MainOverView = driver({
        onCloseClick: () => {
            localStorage.setItem('overviewTour', JSON.stringify(true));
            MainOverView.destroy();
        },
        onNextClick: () => {
            localStorage.setItem('overviewTour', JSON.stringify(true));
            MainOverView.moveNext();
        },
        ...affilateOverview
    });
    const [earningData, setEarningData] = useState([]);
    const [filteredEarningData, setFilteredEarningData] = useState([]);
    const [filter, setFilter] = useState('Alphabet');

    useEffect(() => {
        const fetchEarningData = async () => {
            const response = await getEarningList();
            setEarningData(response.data);
            setFilteredEarningData(response.data);
            calculateMetrics(response.data);
        };
        fetchEarningData();
    }, []);

    useEffect(() => {
        // Apply filtering logic based on the selected filter
        if (filter === 'Alphabet') {
            setFilteredEarningData([...earningData].sort((a, b) => a.title.localeCompare(b.title)));
        } else if (filter === 'Date') {
            setFilteredEarningData([...earningData].sort((a, b) => new Date(b.date) - new Date(a.date)));
        }
    }, [filter, earningData]);

    useEffect(() => {
        debugger;
        const isMainTour = JSON.parse(localStorage.getItem('overviewTour'));
        const user = JSON.parse(localStorage.getItem('user'));
        if (!isMainTour && user.meta_data.overviewTour) {
            MainOverView.drive();
        }
    }, []);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };
    // Dynamic metrics state
    const [metrics, setMetrics] = useState({
        lifetimeEarnings: 0,
        ytdEarnings: 0,
        giftRemindEarnings: 0,
        referralCodesEarnings: 0,
        codesRedeemed: 0,
        subscriptionsGifted: 0
    });
    const calculateMetrics = (data) => {
        const currentYear = new Date().getFullYear();

        const calculatedMetrics = data?.reduce(
            (acc, entry) => {
                const commission = parseFloat(entry.affiliate_commission);
                const entryYear = new Date(entry.date).getFullYear();

                // Lifetime Earnings
                acc.lifetimeEarnings += commission;

                // YTD Earnings (Current Year)
                if (entryYear === currentYear) {
                    acc.ytdEarnings += commission;
                }

                // Gift Remind Earnings
                if (entry.source === 'Gift Remind') {
                    acc.giftRemindEarnings += commission;
                    acc.subscriptionsGifted += 1;
                }

                // Referral Codes Earnings
                if (entry.source === 'Referral Remind') {
                    acc.referralCodesEarnings += commission;
                    acc.codesRedeemed += 1;
                }

                return acc;
            },
            {
                lifetimeEarnings: 0,
                ytdEarnings: 0,
                giftRemindEarnings: 0,
                referralCodesEarnings: 0,
                codesRedeemed: 0,
                subscriptionsGifted: 0
            }
        );

        setMetrics(calculatedMetrics);
    };
    const homeEssentials = {
        items: [
            {
                icon: dollarIcon,
                heading: 'Lifetime Earnings',
                subheading: `$${metrics.lifetimeEarnings.toFixed(2)}`,
                description: '',
                dropdown: false,
                id: 'life-time-earnings'
            },
            {
                icon: dollarIcon,
                heading: 'YTD Earnings',
                subheading: `$${metrics.ytdEarnings.toFixed(2)}`,
                description: '',
                dropdown: false,
                id: 'ytd-earnings'
            },
            {
                icon: dollarIcon,
                heading: 'Gift Remind Earnings',
                subheading: `$${metrics.giftRemindEarnings.toFixed(2)}`,
                description: '',
                id: 'giftings-remind'
            },
            {
                icon: dollarIcon,
                heading: 'Referal Codes Earnings',
                subheading: `$${metrics.referralCodesEarnings.toFixed(2)}`,
                description: '',
                dropdown: false,
                id: 'referal-code'
            },
            {
                icon: hashIcon,
                heading: 'Number of Codes Redeemed',
                subheading: `${metrics.codesRedeemed}`,
                description: '',
                dropdown: true,
                id: 'num-of-code-redeemed'
            },
            {
                icon: giftIconDash,
                heading: 'Number of Subscriptions Gifted',
                subheading: `${metrics.subscriptionsGifted}`,
                description: '',
                dropdown: true,
                id: 'num-of-subscriptions-gifted'
            }
            // {
            //     icon: conservationRate,
            //     heading: "Conversion Rate",
            //     subheading: "57%",
            //     description: false,
            //     dropdown: false
            // },
        ]
    };
    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="">
                        <div className=" paddingRight0">
                            <div className="row">
                                <div className="col-lg-5 col-md-12 col-sm-12">
                                    <h5 className="mb-2 text-titlecase mb-4">Overview</h5>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="row">
                                {homeEssentials.items.map((card) => {
                                    return (
                                        <div className="col-lg-4 item_each_home_cards">
                                            <div className="dashboard-card" id={card.id}>
                                                <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                                                    <div className=" justify-content-between align-items-center ">
                                                        <div className="icon-box-card">
                                                            <img src={card.icon} />{' '}
                                                            <p className="dashboard-subheading">
                                                                {card.subheading}
                                                                {card.dropdown ? (
                                                                    <div className="seller-card-dropdown">
                                                                        <SelectDropDownYTD />
                                                                    </div>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </p>
                                                        </div>
                                                        <p className="dashboard-heading">{card.heading}</p>
                                                        <p className="dashboard-description">{card.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                {/* <div className="col-lg-3 item_each_home_cards lastSellerItem">
                                    <div className="dashboard-card">
                                        <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                                            <div className=" justify-content-between align-items-center ">
                                                <div className="icon-box-card">

                                                </div>
                                                <p className="dashboard-heading">Bonus Targets</p>
                                                <p className="dashboard-description">Explore Targets & Rewards</p>
                                                <Link to='/seller/remind-seller-program' className='dashboard-card-btn'>Explore Targets</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>

                            <div className=" mt-3">
                                <div className="row mb-3">
                                    <div className="col-md-6 width40">
                                        <h2 className="card-heading">Recent Earnings</h2>
                                    </div>
                                    <div className="col-md-6 card-right-action width60">
                                        <div className="recent-earning-sortlble">
                                            <label>Sort by</label>
                                            <Form.Select value={filter} onChange={handleFilterChange}>
                                                <option value="Alphabet">Alphabet</option>
                                                <option value="Date">Date</option>
                                            </Form.Select>
                                        </div>
                                    </div>
                                </div>
                                <RecentEarning earningData={earningData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SellerOverview;
