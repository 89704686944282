import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_SITE_URL + '/wp-json/apis/v1'
});

axiosInstance.interceptors.request.use((config) => {
    /* eslint-disable no-param-reassign */
    const token = localStorage.getItem('token');
    config.headers = {
        Authorization: 'Basic ' + token
    };
    // Remove 'Content-Type' setting here so axios can set it automatically for FormData
    if (config.data instanceof FormData) {
        delete config.headers['Content-Type']; // Let axios set it correctly
    } else {
        config.headers['Content-Type'] = 'application/json'; // For other requests
    }

    return config;
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.data?.message?.includes('expired')) {
            const userDetail = JSON.parse(localStorage.getItem('user'));
            if (userDetail) {
                const trialDate = new Date(userDetail?.meta_data?.trial_end_date);
                const currentDate = new Date();
                if (userDetail?.role === 'homeowner') userDetail['is_plan_active'] = currentDate > trialDate;
                if (userDetail?.role !== 'homeowner') userDetail['is_plan_active'] = false;
                localStorage.setItem('user', JSON.stringify(userDetail));
            }
        }
        if (!error?.response?.data?.message?.includes('expired')) {
            const userDetail = JSON.parse(localStorage.getItem('user'));
            if (userDetail) {
                userDetail['is_plan_active'] = false;
                localStorage.setItem('user', JSON.stringify(userDetail));
            }
        }

        if (error?.response?.data?.message == 'Expired token') {
            toast.error('Session Expired');
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('selectedProperty');
            window.location.reload();
        } else {
            if (error?.response?.data?.code !== 'title_exists') {
                toast.error(error.response.data.message);
            }
        }

        // return error;

        return Promise.reject(error?.response?.data);
    }
);
