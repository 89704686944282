import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import PDFImage from '../../asstes/dash-icons/pdfIcon.svg';
import { getPropertyCategory, getPropertyTypes, getStates } from '../../../axiosCalls/userdashboard';
import he from 'he';

const PropertyDetailsSideNav = ({ setFormData }) => {
    const [propertyDetails, setPropertyDetails] = useState(null);
    const [images, setImages] = useState([]);
    const [thumbnail, setThumbnail] = useState(null);
    const [states, setStates] = useState([]);
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [propertyCategories, setPropertyCategories] = useState([]);
    const [formValues, setFormValues] = useState({
        propertyName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        propertyType: '',
        propertyCategory: '',
        notes: ''
    });

    useEffect(() => {
        const propertyData = localStorage.getItem('selectedProperty');
        if (propertyData) {
            const parsedData = JSON.parse(propertyData);
            setPropertyDetails(parsedData);
            setThumbnail(parsedData.thumbnail_url || null);

            // Safeguard against missing or empty `files`
            const fileArray =
                (parsedData.files?.name || []).map((name, i) => ({
                    id: i,
                    imageUrl: parsedData.files.type[i] === 'application/pdf' ? null : parsedData.files[i],
                    PdfUrl: parsedData.files.type[i] === 'application/pdf' ? parsedData.files[i] : null,
                    file: parsedData.files[i]
                })) || [];

            setImages(fileArray);

            setFormValues({
                propertyName: parsedData.title,
                id: parsedData.id,
                address1: parsedData.address_1,
                address2: parsedData.address_2,
                city: parsedData.city,
                state: parsedData.state,
                postalCode: parsedData.postal_code,
                propertyType: parsedData.type,
                propertyCategory: parsedData.category,
                notes: parsedData.notes
            });

            setFormData({
                ...formValues,
                thumbnail: parsedData.thumbnail_url || null,
                images: fileArray
            });
        }
        fetchBasicData();
    }, []);

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            ...formValues,
            thumbnail: thumbnail,
            images: images
        }));
    }, [formValues, thumbnail, images]);

    const fetchBasicData = async () => {
        try {
            const [statesRes, typesRes, categoriesRes] = await Promise.all([getStates(), getPropertyTypes(), getPropertyCategory()]);
            setStates(statesRes.data);
            setPropertyTypes(typesRes.data);
            setPropertyCategories(categoriesRes.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value
        }));
    };

    const handleThumbnailChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const url = URL.createObjectURL(file);

            setThumbnail(url);

            setFormData((prevData) => ({
                ...prevData,
                postThumbnail: file
            }));
        }
    };

    const handleRemoveThumbnail = () => {
        setThumbnail(null);
        setFormData((prevData) => ({
            ...prevData,
            thumbnail: null
        }));
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const newImages = files.map((file, index) => ({
            id: images.length + index,
            imageUrl: file.type === 'application/pdf' ? null : URL.createObjectURL(file),
            PdfUrl: file.type === 'application/pdf' ? URL.createObjectURL(file) : null,
            file: file
        }));
        setImages((prevImages) => [...prevImages, ...newImages]);
    };

    const removeFile = (id) => {
        setImages((prevImages) => {
            // Remove the file from the images array
            const updatedImages = prevImages.filter((img) => img.id !== id);

            // Update the formData with the latest images
            setFormData((prevData) => ({
                ...prevData,
                images: updatedImages
            }));

            return updatedImages;
        });
    };

    return (
        <div className="side-nav">
            <div className="side-header">
                <h3>Edit Property Details</h3>
            </div>
            <div className="side-body">
                <Form>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Property Name</Form.Label>
                            <Form.Control type="text" value={he.decode(formValues.propertyName || '')} name="propertyName" onChange={handleChange} />
                        </Form.Group>

                        <Form.Group md="12" className="mb-3">
                            <div className="files-choosen">
                                <div className="title">Property Image</div>
                                <div className="button">
                                    <div className="outline-yellow-button">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                        </svg>
                                        Add Property Image
                                        <input type="file" className="chooeseFile" onChange={handleThumbnailChange} accept=".png, .jpeg, .jpg, .webp" />
                                    </div>
                                </div>
                            </div>
                            <div className="listOfImages">
                                {thumbnail && (
                                    <div className="image-choosed">
                                        <img src={thumbnail} alt="Selected property" />
                                        <button onClick={handleRemoveThumbnail} className="image-choosed-crossed">
                                            &times;
                                        </button>
                                    </div>
                                )}
                            </div>
                        </Form.Group>

                        <Form.Group md="12" className="mb-3">
                            <div className="files-choosen">
                                <div className="title">Files</div>
                                <div className="button">
                                    <div className="outline-yellow-button">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#B78C4E" strokeLinecap="round" />
                                        </svg>
                                        Add file
                                        <input type="file" className="chooeseFile" onChange={handleFileChange} multiple accept=".png, .jpeg, .jpg, .webp, .pdf" />
                                    </div>
                                </div>
                            </div>
                            <div className="listOfImages">
                                {images.map((img) => (
                                    <div className="image-choosed" key={img.id}>
                                        {img.PdfUrl ? (
                                            <a href={img.PdfUrl} target="_blank" rel="noopener noreferrer">
                                                <img src={PDFImage} alt="PDF file" />
                                            </a>
                                        ) : (
                                            <img src={img.imageUrl} alt="Property file" />
                                        )}
                                        <button onClick={() => removeFile(img.id)} className="image-choosed-crossed">
                                            &times;
                                        </button>
                                    </div>
                                ))}
                            </div>
                        </Form.Group>

                        {/* Other form fields */}
                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Address 1</Form.Label>
                            <Form.Control type="text" value={formValues.address1} name="address1" onChange={handleChange} />
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Address 2</Form.Label>
                            <Form.Control type="text" value={formValues.address2} name="address2" onChange={handleChange} />
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" value={formValues.city} name="city" onChange={handleChange} />
                        </Form.Group>

                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>State</Form.Label>
                            <Form.Select value={formValues.state} name="state" onChange={handleChange}>
                                {states.map((state) => (
                                    <option key={state.term_id} value={state.abbreviation}>
                                        {state.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} md="6" className="mb-3">
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control type="text" value={formValues.postalCode} name="postalCode" onChange={handleChange} />
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Property Type</Form.Label>
                            <Form.Select value={formValues.propertyType} name="propertyType" onChange={handleChange}>
                                {propertyTypes.map((type) => (
                                    <option key={type.term_id} value={type.term_id}>
                                        {type.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Property Category</Form.Label>
                            <Form.Select value={formValues.propertyCategory} name="propertyCategory" onChange={handleChange}>
                                {propertyCategories.map((category) => (
                                    <option key={category.term_id} value={category.term_id}>
                                        {category.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} md="12" className="mb-3">
                            <Form.Label>Notes</Form.Label>
                            <Form.Control as="textarea" rows={3} value={formValues.notes} name="notes" onChange={handleChange} />
                        </Form.Group>
                    </Row>
                </Form>
            </div>
        </div>
    );
};

export default PropertyDetailsSideNav;
