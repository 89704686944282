import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import RegistrationHomeowner from '../components/auth/inc/registration_homeowner';
import RegistrationAffliate from '../components/auth/inc/registration_affliate';
import RegistrationHomeownerAffliate from '../components/auth/inc/registration_homeowner_affliate';

const Registration = () => {
    const [showPass, setShowPass] = useState(false);

    const [roleSwticher, setRoleSwticher] = useState(null);
    const [roleSwtich, setRoleSwtich] = useState(null);
    const [userData, setUserData] = useState(null);
    // Extract the 'data' parameter from the URL and decode it
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const encodedData = queryParams.get('data');

        if (encodedData) {
            const decodedData = decodeBase64Data(encodedData);

            setUserData(decodedData);
            setRoleSwtich('homeowner');
        }
    }, []);
    useEffect(() => {
        // Function to handle back button (popstate) event
        const handlePopState = () => {
            if (roleSwtich !== null) {
                // Set roleSwitch to null if it has a value
                setRoleSwtich(null);
            }
        };

        // Add popstate event listener
        window.addEventListener('popstate', handlePopState);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [roleSwtich]);

    const handlerRoleSwticher = () => {
        setRoleSwtich(roleSwticher);
        switchRole(roleSwticher);
    };
    // Function to change roleSwitch state and update the browser history
    const switchRole = (newRole) => {
        setRoleSwtich(newRole);
        window.history.pushState({ role: newRole }, '', `/registration/${newRole}`);
    };
    const handlerShowPassword = () => {
        if (showPass) {
            setShowPass(false);
        } else {
            setShowPass(true);
        }
    };
    // Function to decode Base64 and parse the query string
    const decodeBase64Data = (encodedData) => {
        try {
            // Decode the Base64 string
            const decodedString = atob(encodedData);

            // Parse the string into an object
            const params = new URLSearchParams(decodedString);
            const data = {
                email: params.get('email'),
                first_name: params.get('first_name'),
                last_name: params.get('last_name')
            };

            return data;
        } catch (error) {
            console.error('Failed to decode Base64 data:', error);
            return null;
        }
    };

    return (
        <div className="auth-form-light text-left py5 px-4">
            {roleSwtich === null ? (
                <>
                    <div className="brand-logo">
                        <h2 className="auth-title">Registration</h2>
                    </div>

                    <div className="role-selection">
                        <div className="top-text-rol">
                            <span>Please select your role</span>
                        </div>

                        <label className={roleSwticher === 'homeowner' ? 'select-role-section-active select-role-section' : 'select-role-section'} htmlFor="Homeowner">
                            <div className="text-role-selection">Homeowner</div>
                            <div className="radio-role-selection">
                                <input type="radio" id="Homeowner" value="homeowner" onChange={(e) => setRoleSwticher(e.target.value)} className="role-radio-btn" name="role_choose" />
                            </div>
                        </label>

                        <label className={roleSwticher === 'affiliate' ? 'select-role-section-active select-role-section' : 'select-role-section'} htmlFor="Affiliate">
                            <div className="text-role-selection">Affiliate</div>
                            <div className="radio-role-selection">
                                <input type="radio" id="Affiliate" value="affiliate" onChange={(e) => setRoleSwticher(e.target.value)} className="role-radio-btn" name="role_choose" />
                            </div>
                        </label>

                        <label className={roleSwticher === 'homeowner-affiliate' ? 'select-role-section-active select-role-section' : 'select-role-section'} htmlFor="AffiliateHomeowner">
                            <div className="text-role-selection">Homeowner + Affiliate</div>
                            <div className="radio-role-selection">
                                <input type="radio" id="AffiliateHomeowner" onChange={(e) => setRoleSwticher(e.target.value)} value="homeowner-affiliate" className="role-radio-btn" name="role_choose" />
                            </div>
                        </label>

                        <div className="auth-footer">
                            <button type="button" className="auth-success-btn" onClick={handlerRoleSwticher} name="nextbutton">
                                Next
                            </button>
                        </div>
                        <div className="auth-footer">
                            <Link className="auth-link" to="/">
                                I have an account
                            </Link>
                        </div>
                    </div>
                </>
            ) : roleSwtich === 'homeowner' ? (
                <RegistrationHomeowner data={userData} />
            ) : roleSwtich === 'affiliate' ? (
                <RegistrationAffliate />
            ) : roleSwtich === 'homeowner-affiliate' ? (
                <RegistrationHomeownerAffliate />
            ) : (
                ''
            )}
        </div>
    );
};

export default Registration;
