import React, { useEffect, useState } from 'react';
import { Form, InputGroup, Modal } from 'react-bootstrap';
import CalendarCheck from '../../asstes/dash-icons/sidebar/CalendarCheck.svg';
import MagnifyingGlass from '../../asstes/dash-icons/sidebar/MagnifyingGlass.svg';
import { getFilesList, getProjectsList, searchPostsList } from '../../../axiosCalls/userdashboard';
import ReminderSideNavView from './reminderSideNavView';
import HomeEssentialsSideNavView from './homeEssentialsSideNavView';
import PurchasesServiceSideView from './purchasesServiceSideView';
import ProjectsSideNav from './projectsSideNav';
import FilesSideNavView from './filesSideNavView';
import Loader from '../loader/Loader';

const SearchModel = (props) => {
    const sideNavEnum = {
        reminder: 'reminder',
        homeEssential: 'home_essentials',
        purchase_services: 'purchase_services',
        project: 'project',
        files: 'files'
    };
    const [modelShow, setShow] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]); // State to store selected items
    const [selectSidebarType, setSidebarType] = useState('');
    const [sideNavWidth, setSideNavWidth] = useState('0px');
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
    const [listProject, setListProjects] = useState([]);
    const [valueForView, setValueForView] = useState([]);
    const [fileList, setFileList] = useState([]);
    const handleClose = () => {
        setShow(false);
    };

    const handleSearch = async (query) => {
        if (query.length < 1) {
            setResults([]); // Clear results if the query is too short
            return;
        }

        setLoading(true);
        try {
            const response = await searchPostsList(query);
            const data = response.data;
            setResults(data);
        } catch (error) {
            console.error('Error fetching search results:', error);
            setResults([]);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event) => {
        const value = event.target.value;
        setSearchQuery(value);
        handleSearch(value);
    };

    // Grouping results based on post types
    const groupedResults = {
        homeEssentials: [],
        upcomingReminders: [],
        recentPurchasesAndServices: [],
        projects: [],
        files: []
    };

    results.forEach((item) => {
        switch (item.post_type) {
            case 'home_essentials':
                groupedResults.homeEssentials.push(item);
                break;
            case 'event_list':
                groupedResults.upcomingReminders.push(item);
                break;
            case 'purchases':
            case 'services':
                groupedResults.recentPurchasesAndServices.push(item);
                break;
            case 'project':
                groupedResults.projects.push(item);
                break;
            case 'file':
                groupedResults.files.push(item);
                break;
            default:
                break;
        }
    });
    useEffect(() => {
        handleGetProjectList();
        fetchFileList();
    }, []);

    const handleGetProjectList = async () => {
        const res = await getProjectsList();
        if (res && res.data) {
            setListProjects(res.data); // Set the projects list to the state
        }
    };
    const fetchFileList = async () => {
        try {
            const res = await getFilesList();

            setFileList(res?.data || []); // Safely handle response
        } catch (error) {
            console.error('Error fetching files:', error);
        }
    };
    // Function to handle item selection
    const handleItemClick = (item, sidebarType) => {
        setSelectedItems(item);
        if (sidebarType === sideNavEnum.files) {
            setSelectedItems(fileList.find((ii) => ii?.post_id === item?.ID));
        }
        if (sidebarType === sideNavEnum.project) {
            setValueForView(listProject.find((ii) => ii?.ID === item?.ID));
        }
        setSidebarType(sidebarType);
        handleRowSideNavOpen();
    };
    const handlerCancelSideNav = () => {
        setSideNavWidth('sidenavPopuWidthHide');
        setsideNavWidthOverlay('0%');
        document.body.classList.remove('no-scroll');
        setSelectedItems(null);
        setValueForView([]);
    };
    const handleRowSideNavOpen = () => {
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        document.body.classList.add('no-scroll');
    };

    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            </Modal.Header>
            <Modal.Body className="grid-example">
                <Form>
                    <InputGroup className="search-field">
                        <InputGroup.Text id="basic-addon1">
                            <img src={MagnifyingGlass} alt="Search" />
                        </InputGroup.Text>
                        <Form.Control type="text" placeholder="Search in My Sweet House" value={searchQuery} onChange={handleInputChange} />
                    </InputGroup>
                </Form>
                <div className="search-result">
                    {loading && <Loader />}
                    {!loading && results.length === 0 && <div>No results found</div>}
                    {results.length > 0 && (
                        <>
                            {/* Home Essentials */}
                            {groupedResults.homeEssentials.length > 0 && (
                                <div className="search-area-items">
                                    <span className="heading">Home Essentials</span>
                                    {groupedResults.homeEssentials.map((item) => (
                                        <div key={item.ID} className="icon-list" onClick={() => handleItemClick(item, sideNavEnum.homeEssential)}>
                                            <span className="icon">
                                                <img src={CalendarCheck} alt="Icon" />
                                            </span>
                                            <span className="icon-content">{item.title}</span>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {/* Upcoming Reminders */}
                            {groupedResults.upcomingReminders.length > 0 && (
                                <div className="search-area-items">
                                    <span className="heading">Upcoming Reminders</span>
                                    {groupedResults.upcomingReminders.map((item) => (
                                        <div key={item.ID} className="icon-list" onClick={() => handleItemClick(item, sideNavEnum.reminder)}>
                                            <span className="icon">
                                                <img src={CalendarCheck} alt="Icon" />
                                            </span>
                                            <span className="icon-content">{item.title}</span>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {/* Recent Purchases & Services */}
                            {groupedResults.recentPurchasesAndServices.length > 0 && (
                                <div className="search-area-items">
                                    <span className="heading">Recent Purchases & Services</span>
                                    {groupedResults.recentPurchasesAndServices.map((item) => (
                                        <div key={item.ID} className="icon-list" onClick={() => handleItemClick(item, sideNavEnum.purchase_services)}>
                                            <span className="icon">
                                                <img src={CalendarCheck} alt="Icon" />
                                            </span>
                                            <span className="icon-content">{item.title}</span>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {/* Projects */}
                            {groupedResults.projects.length > 0 && (
                                <div className="search-area-items">
                                    <span className="heading">Projects</span>
                                    {groupedResults.projects.map((item) => (
                                        <div key={item.ID} className="icon-list" onClick={() => handleItemClick(item, sideNavEnum.project)}>
                                            <span className="icon">
                                                <img src={CalendarCheck} alt="Icon" />
                                            </span>
                                            <span className="icon-content">{item.title}</span>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {/* Files */}
                            {groupedResults.files.length > 0 && (
                                <div className="search-area-items">
                                    <span className="heading">Files</span>
                                    {groupedResults.files.map((item) => (
                                        <div key={item.ID} className="icon-list" onClick={() => handleItemClick(item, sideNavEnum.files)}>
                                            <span className="icon">
                                                <img src={CalendarCheck} alt="Icon" />
                                            </span>
                                            <span className="icon-content">{item.title}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </>
                    )}
                    <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>
                    <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>
                        {selectSidebarType == sideNavEnum.homeEssential ? (
                            <HomeEssentialsSideNavView propsValue={selectedItems?.ID} />
                        ) : selectSidebarType == sideNavEnum.reminder ? (
                            <ReminderSideNavView
                                handleClose={() => {
                                    handlerCancelSideNav();
                                }}
                                eventID={selectedItems?.ID}
                            />
                        ) : selectSidebarType == sideNavEnum.purchase_services ? (
                            <PurchasesServiceSideView selectedId={selectedItems?.ID} />
                        ) : selectSidebarType == sideNavEnum.project ? (
                            <ProjectsSideNav propsValue={valueForView} />
                        ) : selectSidebarType == sideNavEnum.files ? (
                            <FilesSideNavView selectValue={selectedItems} />
                        ) : null}
                        {sideNavWidth !== '0px' && (
                            <div className="side-footer">
                                <button className="footer-button-cancel" onClick={handlerCancelSideNav}>
                                    Cancel
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SearchModel;
