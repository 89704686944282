import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import '../../../asstes/styles/loginStyles.css';
import appleIcon from '../../../asstes/dash-icons/auth/appleIcon.svg';
import fbIcon from '../../../asstes/dash-icons/auth/fbIcon.svg';
import XIcon from '../../../asstes/dash-icons/auth/XIcon.svg';
import googleIcon from '../../../asstes/dash-icons/auth/googleIcon.svg';
import EyeSlash from '../../../asstes/dash-icons/auth/EyeSlash.svg';
import EyeSlashCross from '../../../asstes/dash-icons/auth/EyeSlashCross.svg';
import { Link, useNavigate } from 'react-router-dom';
import { InputGroup } from 'react-bootstrap';
import { signUpApis } from '../../../../axiosCalls/auth';
import { toast } from 'react-toastify';

const RegistrationHomeowner = ({ data }) => {
    const [showPass, setShowPass] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        referralCode: '',
        agreeTerms: false
    });
    // Use useEffect to update the form data when `data` is available
    useEffect(() => {
        if (data) {
            setFormData((prev) => ({
                ...prev,
                firstName: data.first_name,
                lastName: data.last_name,
                email: data.email
            }));
        }
    }, [data]);
    const navigate = useNavigate();

    const handlerShowPassword = () => {
        setShowPass(!showPass);
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.firstName || !formData.lastName || !formData.email || !formData.password || !formData.confirmPassword) {
            toast.error('All fields are required.');
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            toast.error('Passwords do not match.');
            return;
        }

        if (!formData.agreeTerms) {
            toast.error('You must agree to the terms and conditions.');
            return;
        }

        const registrationData = {
            username: formData.email.split('@')[0],
            email: formData.email,
            password: formData.password,
            first_name: formData.firstName,
            last_name: formData.lastName,
            role: 'homeowner',
            status: 'active',
            meta_data: [
                {
                    meta_name: 'referral_code',
                    meta_value: formData.referralCode
                }
            ]
        };

        try {
            const response = await signUpApis(registrationData);
            if (response) {
                toast.success('Registration successful!');
                // navigate("/login");
            }
        } catch (error) {
            console.error('Registration error:', error);
        }
    };

    return (
        <div className="">
            <div className="brand-logo">
                <h2 className="auth-title">
                    Register as <span className="subheading">Homeowner</span>
                </h2>
                <div className="auth-logos">
                    {/* <img src={appleIcon} alt="appleIcon" /> */}
                    <a href={`${process.env.REACT_APP_SITE_URL}/remindhome-login/?loginSocial=google`}>
                        <img src={googleIcon} alt="googleIcon" />
                    </a>

                    <a href={`${process.env.REACT_APP_SITE_URL}/remindhome-login/?loginSocial=twitter`}>
                        <img src={XIcon} alt="XIcon" />
                    </a>
                    <img src={fbIcon} alt="fbIcon" />
                </div>
            </div>

            <h6 className="font-weight-light">Or sign up using email</h6>
            <form className="pt-3" onSubmit={handleSubmit}>
                <div className="row firstlast_row">
                    <CustomInput
                        label={'First name'}
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                        name="firstName"
                        ariaLabel="First name"
                        className="form-control"
                        placeholder="Michael"
                        conntainerClass={'col'}
                        labelClass={'pass-label flex-row '}
                        type={'text'}
                    />
                    <CustomInput label={'Last name'} value={formData.lastName} onChange={handleInputChange} required className="form-control" placeholder="Jackson" aria-label="Last name" name="lastName" conntainerClass={'col'} labelClass={'pass-label flex-row '} type={'text'} />
                </div>
                <CustomInput label={'Email'} className="form-control form-control-lg" id="email" placeholder="mjack@gmail.com" name="email" value={formData.email} onChange={handleInputChange} autoComplete="off" required conntainerClass={'form-group'} type={'email'} />

                <InputGroup>
                    <label className="pass-label flex-row" htmlFor="password">
                        Password
                        <span className="required">*</span>
                    </label>
                    <input type={showPass ? 'text' : 'password'} className="form-control form-control-lg auth-password" id="password" name="password" value={formData.password} onChange={handleInputChange} autoComplete="off" required />
                    <InputGroup.Text id="basic-addon1" onClick={handlerShowPassword}>
                        <img src={showPass ? EyeSlashCross : EyeSlash} alt="toggle visibility" />
                    </InputGroup.Text>
                </InputGroup>

                <InputGroup>
                    <label className="pass-label flex-row" htmlFor="confirmPassword">
                        Confirm password <span className="required">*</span>
                    </label>
                    <input type={showPass ? 'text' : 'password'} className="form-control form-control-lg auth-password" id="confirmPassword" name="confirmPassword" value={formData.confirmPassword} onChange={handleInputChange} autoComplete="off" required />
                    <InputGroup.Text id="basic-addon1" onClick={handlerShowPassword}>
                        <img src={showPass ? EyeSlashCross : EyeSlash} alt="toggle visibility" />
                    </InputGroup.Text>
                </InputGroup>

                <div className="form-group">
                    <label htmlFor="referralCode">Referral code</label>
                    <input type="text" className="form-control form-control-lg" id="referralCode" name="referralCode" value={formData.referralCode} onChange={handleInputChange} />
                </div>

                <div className="col-12">
                    <div className="form-check check-me">
                        <input className="form-check-input" type="checkbox" id="agreeTerms" name="agreeTerms" checked={formData.agreeTerms} onChange={handleInputChange} required />
                        <label className="form-check-label" htmlFor="agreeTerms">
                            I have read and agree to remind Home Solutions'{' '}
                            <Link target="_blank" to={`${process.env.REACT_APP_SITE_URL}/terms-and-conditions`}>
                                Terms and Conditions
                            </Link>{' '}
                            and acknowledge the{' '}
                            <Link target="_blank" to={`${process.env.REACT_APP_SITE_URL}/privacy-policy`}>
                                Privacy Policy
                            </Link>
                            .
                        </label>
                    </div>
                </div>

                <div className="mt-3">
                    <button type="submit" className="auth-success-btn">
                        Sign Up for Free Trial
                    </button>
                </div>
            </form>
            <div className="text-center mt-4 font-weight-light">
                <Link className="auth-link" to="/login">
                    I have an account
                </Link>
            </div>
        </div>
    );
};
const CustomInput = ({ label, value, onChange, required, className, placeholder, name, ariaLabel, conntainerClass, labelClass, id, type }) => {
    return (
        <>
            <div className={conntainerClass}>
                <label className={labelClass} htmlFor={name}>
                    {label} {required && <span className="required">*</span>}
                </label>
                <input type={type} className={className} placeholder={placeholder} id={id} aria-label={ariaLabel} name={name} value={value} onChange={onChange} autoComplete="off" required />
            </div>
        </>
    );
};

export default RegistrationHomeowner;
