import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Dashboard from './user-dashboard/pages/dashboard';
import DashLayouts from './user-dashboard/components/layouts/dashLayouts';
import AuthLayouts from './user-dashboard/components/layouts/authLayouts';
import Registration from './user-dashboard/pages/registration';
import ForgotPassword from './user-dashboard/pages/forgot-password';
import Login from './user-dashboard/pages/login';
import Reminders from './user-dashboard/pages/reminders';
import HomeEssentials from './user-dashboard/pages/home-essentials';
import PurchasesServices from './user-dashboard/pages/purchases-services';
import Projects from './user-dashboard/pages/projects';
import PropertyReports from './user-dashboard/pages/property-reports';
import PropertyDetails from './user-dashboard/pages/property-details';
import Files from './user-dashboard/pages/files';
import UserSetting from './user-dashboard/pages/user-setting';
import SellerDashboard from './seller/layouts/sellerDashboard';
import SellerOverview from './seller/pages/overview';
import GiftReferReminders from './seller/pages/gift-refer-reminders';
import MyNetwork from './seller/pages/MyNetwork';
import REmindSellerProgram from './seller/pages/REmindSellerProgram';
import SellerPayments from './seller/pages/payments';
import AffiliateTaxRequirements from './seller/pages/AffiliateTaxRequirements';
import SellerUserSetting from './seller/pages/seller-user-setting';
import REmindSellerProgramGraph from './seller/pages/REmindSellerProgramGraph';
import ProtectedRoute from './ProtectedRoute'; // Make sure to adjust the path
import ResetPassword from './user-dashboard/pages/reset-password';
import NotFound from './user-dashboard/pages/not-found';

function CreateRoutes() {
    const user = JSON.parse(localStorage.getItem('user')); // Adjust the key as per your setup

    return (
        <>
            {/* dashboard pages routes */}
            <Routes>
                {/* main page */}

                <Route
                    path="/dashboard"
                    element={
                        <ProtectedRoute allowedRoles={['homeowner', 'homeowner_affiliate']}>
                            <DashLayouts childComp={<Dashboard />} />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="/reminders"
                    element={
                        <ProtectedRoute allowedRoles={['homeowner', 'homeowner_affiliate']}>
                            <DashLayouts childComp={<Reminders />} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/home-essentials"
                    element={
                        <ProtectedRoute allowedRoles={['homeowner', 'homeowner_affiliate']}>
                            <DashLayouts childComp={<HomeEssentials />} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/purchases-services"
                    element={
                        <ProtectedRoute allowedRoles={['homeowner', 'homeowner_affiliate']}>
                            <DashLayouts childComp={<PurchasesServices />} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/projects"
                    element={
                        <ProtectedRoute allowedRoles={['homeowner', 'homeowner_affiliate']}>
                            <DashLayouts childComp={<Projects />} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/property-reports"
                    element={
                        <ProtectedRoute allowedRoles={['homeowner', 'homeowner_affiliate']}>
                            <DashLayouts childComp={<PropertyReports />} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/property-details"
                    element={
                        <ProtectedRoute allowedRoles={['homeowner', 'homeowner_affiliate']}>
                            <DashLayouts childComp={<PropertyDetails />} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/files"
                    element={
                        <ProtectedRoute allowedRoles={['homeowner', 'homeowner_affiliate']}>
                            <DashLayouts childComp={<Files />} />
                        </ProtectedRoute>
                    }
                />
                <Route path="/user-setting" element={<DashLayouts childComp={<UserSetting />} />} />

                {/* Auth Pages */}
                <Route path="/" element={<AuthLayouts childComp={<Login />} />} />
                <Route path="/login" element={<AuthLayouts childComp={<Login />} />} />
                <Route path="/registration" element={<AuthLayouts childComp={<Registration />} />} />
                <Route path="/forgot-password" element={<AuthLayouts childComp={<ForgotPassword />} />} />
                <Route path="/reset-password" element={<AuthLayouts childComp={<ResetPassword />} />} />

                {/* Seller Dashboard */}
                <Route
                    path="/seller"
                    element={
                        <ProtectedRoute allowedRoles={['affiliate', 'homeowner_affiliate']}>
                            <SellerDashboard childComp={<SellerOverview />} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/seller/overview"
                    element={
                        <ProtectedRoute allowedRoles={['affiliate', 'homeowner_affiliate']}>
                            <SellerDashboard childComp={<SellerOverview />} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/seller/gift-refer-reminders"
                    element={
                        <ProtectedRoute allowedRoles={['affiliate', 'homeowner_affiliate']}>
                            <SellerDashboard childComp={<GiftReferReminders />} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/seller/my-network"
                    element={
                        <ProtectedRoute allowedRoles={['affiliate', 'homeowner_affiliate']}>
                            <SellerDashboard childComp={<MyNetwork />} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/seller/remind-seller-program"
                    element={
                        <ProtectedRoute allowedRoles={['affiliate', 'homeowner_affiliate']}>
                            <SellerDashboard childComp={<REmindSellerProgram />} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/seller/remind-seller-program-graph"
                    element={
                        <ProtectedRoute allowedRoles={['affiliate', 'homeowner_affiliate']}>
                            <SellerDashboard childComp={<REmindSellerProgramGraph />} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/seller/seller-payment"
                    element={
                        <ProtectedRoute allowedRoles={['affiliate', 'homeowner_affiliate']}>
                            <SellerDashboard childComp={<SellerPayments />} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/seller/seller-affiliate-tax-requirements"
                    element={
                        <ProtectedRoute allowedRoles={['affiliate', 'homeowner_affiliate']}>
                            <SellerDashboard childComp={<AffiliateTaxRequirements />} />
                        </ProtectedRoute>
                    }
                />
                <Route path="/seller/seller-user-setting" element={<SellerDashboard childComp={<SellerUserSetting />} />} />

                {/* Catch-all route for 404 */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
}

export default CreateRoutes;
