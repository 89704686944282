import React, { useState, useEffect, useRef } from 'react';
import { InputGroup } from 'react-bootstrap';
import EyeSlashCross from '../asstes/dash-icons/auth/EyeSlashCross.svg';
import EyeSlash from '../asstes/dash-icons/auth/EyeSlash.svg';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetPassword } from '../../axiosCalls/auth';

export default function ResetPassword() {
    const [showPass, setShowPass] = useState(false);
    const [keyValid, setKeyValid] = useState(true);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const resetKey = searchParams.get('key');
    const login = searchParams.get('login');

    const handlerShowPassword = () => {
        setShowPass(!showPass);
    };

    const effectRun = useRef(false); // To track if the effect has already run

    useEffect(() => {
        if (effectRun.current) return; // Prevent the effect from running twice
        effectRun.current = true;

        if (!resetKey || !login) {
            toast.error('Invalid or missing reset key.');
            navigate('/login');
        } else {
            // Simulate a check for the validity of the reset key (replace this with actual API call)
            const checkKeyValidity = async () => {
                // Replace with your API call to verify the key
                const response = await resetPassword({ key: resetKey, login });

                if (response.status !== 200) {
                    setKeyValid(false);
                    // navigate('/login'); // Redirect if key is invalid
                }
            };
            checkKeyValidity();
        }
    }, [resetKey, login, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!keyValid) return;

        const password = e.target.password.value;
        const confirmPassword = e.target.confirmPassword.value;

        if (password !== confirmPassword) {
            toast.error('Passwords do not match.');
            return;
        }

        const response = await resetPassword({
            key: resetKey,
            login,
            new_password: password
        });

        if (response.status === 200) {
            toast.success('Password reset successfully.');
            navigate('/login');
        } else {
            toast.error(response.message || 'Error resetting password.');
        }
    };

    return (
        <div className="auth-form-light text-left py-5 px-4">
            <div className="brand-logo">
                <h2 className="auth-title">Reset Password</h2>
            </div>
            <h6 className="font-weight-light">Set your new password</h6>
            <form className="pt-3" onSubmit={handleSubmit}>
                <InputGroup>
                    <label className="pass-label" htmlFor="password">
                        Password
                    </label>
                    <input
                        type={showPass ? 'text' : 'password'}
                        className="form-control form-control-lg auth-password"
                        id="password"
                        name="password"
                        autoComplete="off"
                        required
                        disabled={!keyValid} // Disable input if key is invalid
                    />
                    <InputGroup.Text id="basic-addon1" onClick={handlerShowPassword}>
                        <img src={showPass ? EyeSlashCross : EyeSlash} alt="toggle visibility" />
                    </InputGroup.Text>
                </InputGroup>

                <InputGroup>
                    <label className="pass-label" htmlFor="confirmPassword">
                        Confirm password
                    </label>
                    <input
                        type={showPass ? 'text' : 'password'}
                        className="form-control form-control-lg auth-password"
                        id="confirmPassword"
                        name="confirmPassword"
                        autoComplete="off"
                        required
                        disabled={!keyValid} // Disable input if key is invalid
                    />
                    <InputGroup.Text id="basic-addon1" onClick={handlerShowPassword}>
                        <img src={showPass ? EyeSlashCross : EyeSlash} alt="toggle visibility" />
                    </InputGroup.Text>
                </InputGroup>

                <div className="mt-3">
                    <button
                        type="submit"
                        className="auth-success-btn"
                        disabled={!keyValid} // Disable button if key is invalid
                    >
                        Set New Password
                    </button>
                </div>
                <div className="auth-footer">
                    <Link className="auth-link" to="/login">
                        Continue to Login
                    </Link>
                </div>
            </form>
            <ToastContainer />
        </div>
    );
}
