import React, { useState, useEffect } from 'react';
import placeHolderImageProfile from '../asstes/images/placeHolderImageProfile.svg';
import PropertyDetailsSideNav from '../components/inc/propertyDetailsSideNav';
import { getPropertyById, getTaxonomyById, updatePropertyData } from '../../axiosCalls/userdashboard';
import { toast } from 'react-toastify';
import Loader from '../components/loader/Loader';

const PropertyDetails = () => {
    const [sideNavWidth, setSideNavWidth] = useState('0px');
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
    const [continueChange, setContinueChange] = useState('createStep');
    const [propertyDetails, setPropertyDetails] = useState(null);
    const [propertyCategoryName, setPropertyCategoryName] = useState(null);
    const [propertyTypeName, setPropertyTypeName] = useState(null);
    const [valueForView, setValueForView] = useState({});
    const [propertyStateName, setPropertyStateName] = useState(null);
    const [formValues, setFormValues] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const propertyData = localStorage.getItem('selectedProperty');
        if (propertyData) {
            setIsLoading(true);
            const parsedData = JSON.parse(propertyData);
            setPropertyDetails(parsedData);
            fetchTaxonomyDetails(parsedData);
            setIsLoading(false);
        }

        const handleStorageChange = () => {
            const updatedPropertyData = localStorage.getItem('selectedProperty');
            if (updatedPropertyData) {
                const parsedData = JSON.parse(updatedPropertyData);
                setPropertyDetails(parsedData);
                fetchTaxonomyDetails(parsedData);
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const fetchTaxonomyDetails = async ({ category, type, state }) => {
        try {
            if (!category || !type || !state) {
                return;
            }
            const [categoryRes, typeRes, stateRes] = await Promise.all([getTaxonomyById('property-category', category), getTaxonomyById('property-type', type), getTaxonomyById('states', state)]);

            setPropertyCategoryName(categoryRes.data.name);
            setPropertyTypeName(typeRes.data.name);
            setPropertyStateName(stateRes.data.name);
        } catch (error) {
            console.error('Error fetching taxonomy details:', error);
        }
    };

    const handlerReminderSideNave = () => {
        setContinueChange('createStep');
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        document.body.classList.add('no-scroll');
    };

    const handlerCancelSideNav = () => {
        setSideNavWidth('sidenavPopuWidthHide');
        setsideNavWidthOverlay('0%');
        document.body.classList.remove('no-scroll');
    };

    const handlerChangeStep = () => {
        if (continueChange === 'suggets') {
            // setContinueChange('createStep')
        }
    };

    const handlerBackSideNav = () => {
        if (continueChange === 'createStep') {
            setContinueChange('suggets');
        }
    };

    const handlerUpdatePropertiesDetails = async () => {
        // Validate required fields
        // if (!formValues.propertyName || !formValues.address1 || !formValues.city || !formValues.state || !formValues.postalCode || !formValues.propertyType || !formValues.propertyCategory || !formValues.thumbnail) {
        //     toast.error('Please fill out all required fields.');
        //     // return;
        // }

        setIsLoading(true);
        const data = new FormData();

        data.append('title', formValues.propertyName);
        data.append('address_1', formValues.address1);
        data.append('address_2', formValues.address2);
        data.append('city', formValues.city);
        data.append('state', formValues.state);
        data.append('postal_code', formValues.postalCode);
        data.append('type', formValues.propertyType);
        data.append('category', formValues.propertyCategory);
        data.append('notes', formValues.notes);
        data.append('oldFiles', JSON.stringify(formValues.images));
        data.append('oldPropertyImg', JSON.stringify(formValues.thumbnail));

        if (formValues.postThumbnail) {
            data.append('property_image', formValues.postThumbnail);
        }

        if (formValues.images) {
            for (let i = 0; i < formValues.images.length; i++) {
                if (formValues.images[i].file && formValues.images[i].file.name) {
                    data.append('files[]', formValues.images[i].file);
                }
            }
        }

        try {
            const response = await updatePropertyData(data, formValues.id);
            if (response && response.data) {
                const res = await getPropertyById(formValues.id);
                localStorage.setItem('selectedProperty', JSON.stringify(res.data));
                setPropertyDetails(res.data); // Update the property details
                fetchTaxonomyDetails(res.data); // Fetch taxonomy details
                setIsLoading(false);
                toast.success(response.data.message);
                window.location.reload();
            }
        } catch (error) {
            console.error('Error updating property details:', error);
        }
    };

    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="row">
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <h5 className="mb-2 text-titlecase mb-4">Property Details</h5>
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 d-flex text-top-subtext">
                            <div className="col-md-4 top-text-head">
                                <div className="reminder-top-button">
                                    <button className="filled-btn" onClick={handlerReminderSideNave}>
                                        Edit Details
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Property Details Board */}
                    {isLoading ? (
                        <Loader />
                    ) : (
                        propertyDetails && (
                            <div className="row dashboard-card-parent">
                                <div className="row">
                                    <div className="col-md-12 property_details">
                                        <div className="property_details_Image">
                                            <img src={propertyDetails.thumbnail_url || placeHolderImageProfile} alt="Property Thumbnail" />
                                        </div>
                                        <div className="property_details_area">
                                            <div className="proteryDetailsItem">
                                                <label>Name:</label>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: propertyDetails.title
                                                    }}
                                                ></span>
                                            </div>
                                            <div className="proteryDetailsItem">
                                                <label>Address:</label>
                                                <span>{[propertyDetails.address_1, propertyDetails.address_2, propertyDetails.city, propertyStateName, propertyDetails.postal_code].filter(Boolean).join(', ')}</span>
                                            </div>

                                            <div className="proteryDetailsItem">
                                                <label>Category:</label>
                                                <span>{propertyCategoryName}</span>
                                            </div>
                                            <div className="proteryDetailsItem">
                                                <label>Type:</label>
                                                <span>{propertyTypeName}</span>
                                            </div>
                                            <div className="proteryDetailsItem">
                                                <label>Notes:</label>
                                                <span>{propertyDetails.notes}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
            <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>

            <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>
                {continueChange === 'suggets' ? <PropertyDetailsSideNav propsValue={valueForView} setFormData={setFormValues} /> : continueChange === 'createStep' ? <PropertyDetailsSideNav setFormData={setFormValues} /> : ''}

                <div className="side-footer">
                    <button className="footer-button-cancel" onClick={handlerCancelSideNav}>
                        Cancel
                    </button>
                    <button className="footer-button-primary" disabled={isLoading} onClick={handlerUpdatePropertiesDetails}>
                        {isLoading ? <Loader size="sm" /> : 'Save'}
                    </button>
                    {continueChange !== 'createStep' ? (
                        <button className="footer-button-cancel footer-button-back" onClick={handlerBackSideNav}>
                            Back
                        </button>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};

export default PropertyDetails;
