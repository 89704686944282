import React from 'react';

const Purchases = () => {
    return (
        <div className="col-md-12 mb-3" id="project-cost">
            <label className="reportSectiontitile">Purchases</label>
            <div className="recentPurcheseTable projectTableReports">Purchases</div>
        </div>
    );
};

export default Purchases;
