import React from 'react';

const ExpensesTotal = () => {
    return (
        <div className=" col-md-12 mb-3" id="expense-total">
            <label className="reportSectiontitile">Expense Totals</label>
            <div className="expenseTable">
                <div className="expensItem">
                    <div className="expensTitle">Home Essentials</div>
                    <div className="expensAmount">$4,985</div>
                </div>
                <div className="expensItem">
                    <div className="expensTitle">Purchases</div>
                    <div className="expensAmount">$4,985</div>
                </div>

                <div className="expensItem">
                    <div className="expensTitle">Services</div>
                    <div className="expensAmount">$4,985</div>
                </div>

                <div className="expensItem TotalLast">
                    <div className="expensTitle">Total</div>
                    <div className="expensAmount">$6,637</div>
                </div>
            </div>
        </div>
    );
};

export default ExpensesTotal;
