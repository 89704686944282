import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CustomReminderForm from '../common/CustomReminderForm';
import { addCustomReminder, addNotificationData } from '../../../axiosCalls/userdashboard';
import './style.css';

const AddRemindertModal = ({ showModal, handleClose, fetchReminders, resetForm }) => {
    const [isLoading, setIsLoading] = useState(false); // State to track loading status
    const [reminderData, setReminderData] = useState({}); // State to store form data

    const handleReminderDataChange = (data) => {
        setReminderData(data); // Update state with form data
    };

    const onSubmit = async () => {
        try {
            setIsLoading(true);
            const response = await addCustomReminder(reminderData);
            fetchReminders();
            toast.success(response?.message || 'Reminder added successfully');
            handleClose();
        } catch (error) {
            console.error('Error adding reminder:', error);
            toast.error('An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="text-center w-100 mt-2">Add Reminder</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomReminderForm onChange={handleReminderDataChange} />
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
                <Button variant="secondary" onClick={handleClose} className="mr-2 button-secondary-contact">
                    Close
                </Button>
                <Button className="button-primary-contact" variant="primary" onClick={onSubmit} disabled={isLoading}>
                    {isLoading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddRemindertModal;
