import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            {/* <div className="card"> */}
            <div className="card-body">
                <div className="d-flex justify-content-center justify-content-sm-between inner-body-card-footer">
                    <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                        {' '}
                        Design & Development by{' '}
                        <a href="https://www.ropstam.com/" target="_blank" rel="noreferrer">
                            <b>Ropstam Solutions Inc.</b>
                        </a>
                    </span>
                    <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                        <span>
                            If you have any queries, please feel free to{' '}
                            <Link target="_blank" to="https://remindhome.ropstam.dev/#contact_us_remind">
                                <b>Contact us</b>
                            </Link>
                        </span>
                    </span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
