import React, { useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { forgotPassword } from '../../axiosCalls/auth';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!email) {
            toast.error('Please enter your email.');
            return;
        }

        const requestData = {
            username_or_email: email
        };

        try {
            const res = await forgotPassword(requestData);

            toast.success(res.data.message);
            navigate('/login');
        } catch (error) {
            console.error('Error sending password reset link:', error);
        }
    };

    return (
        <div className="auth-form-light text-left py-5 px-4">
            <div className="brand-logo">
                <h2 className="auth-title">Forgot Password</h2>
            </div>
            <h6 className="font-weight-light">Forgot using email</h6>
            <form className="pt-3" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">
                        Email <span className="required">*</span>{' '}
                    </label>
                    <input type="email" className="form-control form-control-lg" id="exampleInputEmail1" autoComplete="off" required placeholder="Enter your email" value={email} onChange={handleInputChange} />
                </div>
                <div className="mt-3">
                    <button type="submit" className="auth-success-btn">
                        Forgot Password
                    </button>
                </div>
                <div className="auth-footer">
                    <Link className="auth-link" to="/login">
                        Continue to Login
                    </Link>
                </div>
            </form>
        </div>
    );
};

export default ForgotPassword;
