import React, { useEffect, useState } from 'react';
import RecentEarning from '../inc/recent-earning';
import { getEarningList } from '../../axiosCalls/seller';

const REmindSellerProgram = () => {
    const [earningData, setEarningData] = useState([]);
    const [filteredEarningData, setFilteredEarningData] = useState([]);
    const [filter, setFilter] = useState('Alphabet');

    useEffect(() => {
        const fetchEarningData = async () => {
            const response = await getEarningList();
            setEarningData(response.data);
            setFilteredEarningData(response.data);
        };
        fetchEarningData();
    }, []);

    useEffect(() => {
        if (filter === 'Alphabet') {
            setFilteredEarningData([...earningData].sort((a, b) => a.title.localeCompare(b.title)));
        } else if (filter === 'Date') {
            setFilteredEarningData([...earningData].sort((a, b) => new Date(b.date) - new Date(a.date)));
        }
    }, [filter, earningData]);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div>
                        <div className="paddingRight0">
                            <div className="mt-3">
                                <div className="row mb-3">
                                    <div className="col-md-6 width40">
                                        <h2 className="card-heading">Earnings</h2>
                                    </div>
                                    <div className="col-md-6 card-right-action width60">
                                        <div className="recent-earning-sortable">
                                            <label>Sort by</label>
                                            <select value={filter} onChange={handleFilterChange} className="form-select">
                                                <option value="Alphabet">Alphabet</option>
                                                <option value="Date">Date</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <RecentEarning earningData={filteredEarningData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default REmindSellerProgram;
