import React from 'react';
import DataTable, { Alignment, Direction } from 'react-data-table-component';
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';
import eyeIcon from '../../asstes/dash-icons/eyeIcon.svg';
import downloadIcon from '../../asstes/dash-icons/downloadIcon.svg';
import deleteIcon from '../../asstes/dash-icons/deleteIcon.svg';

const Files = () => {
    const columnsFiles = [
        {
            name: 'File name',
            selector: (row) => row.filename,
            sortable: false,
            reorder: true
        },
        {
            name: 'Type',
            selector: (row) => row.Type,
            sortable: false,
            reorder: true
        },
        {
            name: 'Notes',
            selector: (row) => row.Notes,
            sortable: false,
            reorder: true
        },
        {
            name: '',
            selector: (row) => row.action,
            sortable: false,
            reorder: true
        }
    ];
    const dataFiles = [
        {
            id: 1,
            filename: (
                <div className="fileName">
                    <img src={pdfIcon} />
                    document_name.pdf
                </div>
            ),
            action: (
                <div className="actions">
                    <span>
                        <img src={eyeIcon} />
                    </span>
                    <span>
                        <img src={downloadIcon} />
                    </span>
                    <span>
                        <img src={deleteIcon} />
                    </span>
                </div>
            ),
            Type: 'Closing type',
            Notes: 'Note'
        },
        {
            id: 1,
            filename: (
                <div className="fileName">
                    <img src={pdfIcon} />
                    document_name.pdf
                </div>
            ),
            action: (
                <div className="actions">
                    <span>
                        <img src={eyeIcon} />
                    </span>
                    <span>
                        <img src={downloadIcon} />
                    </span>
                    <span>
                        <img src={deleteIcon} />
                    </span>
                </div>
            ),
            Type: 'Closing type',
            Notes: 'Note'
        },
        {
            id: 1,
            filename: (
                <div className="fileName">
                    <img src={pdfIcon} />
                    document_name.pdf
                </div>
            ),
            action: (
                <div className="actions">
                    <span>
                        <img src={eyeIcon} />
                    </span>
                    <span>
                        <img src={downloadIcon} />
                    </span>
                    <span>
                        <img src={deleteIcon} />
                    </span>
                </div>
            ),
            Type: 'Closing type',
            Notes: 'Note'
        },
        {
            id: 1,
            filename: (
                <div className="fileName">
                    <img src={pdfIcon} />
                    document_name.pdf
                </div>
            ),
            action: (
                <div className="actions">
                    <span>
                        <img src={eyeIcon} />
                    </span>
                    <span>
                        <img src={downloadIcon} />
                    </span>
                    <span>
                        <img src={deleteIcon} />
                    </span>
                </div>
            ),
            Type: 'Closing type',
            Notes: 'Note'
        },
        {
            id: 1,
            filename: (
                <div className="fileName">
                    <img src={pdfIcon} />
                    document_name.pdf
                </div>
            ),
            action: (
                <div className="actions">
                    <span>
                        <img src={eyeIcon} />
                    </span>
                    <span>
                        <img src={downloadIcon} />
                    </span>
                    <span>
                        <img src={deleteIcon} />
                    </span>
                </div>
            ),
            Type: 'Closing type',
            Notes: 'Note'
        },
        {
            id: 1,
            filename: (
                <div className="fileName">
                    <img src={pdfIcon} />
                    document_name.pdf
                </div>
            ),
            action: (
                <div className="actions">
                    <span>
                        <img src={eyeIcon} />
                    </span>
                    <span>
                        <img src={downloadIcon} />
                    </span>
                    <span>
                        <img src={deleteIcon} />
                    </span>
                </div>
            ),
            Type: 'Closing type',
            Notes: 'Note'
        }
    ];
    return (
        <div className="col-md-12 mb-3" id="property-files">
            <label className="reportSectiontitile">Property Files</label>
            <div className="recentFilesTable ">
                <DataTable
                    columns={columnsFiles}
                    data={dataFiles}
                    defaultSortFieldId={2}
                    selectableRows={false}
                    selectableRowsNoSelectAll={false}
                    selectableRowsVisibleOnly={false}
                    selectableRowsHighlight={false}
                    selectableRowsSingle={false}
                    expandableRows={false}
                    expandOnRowClicked={false}
                    expandOnRowDoubleClicked={false}
                    expandableRowsHideExpander={false}
                    pagination={false}
                    highlightOnHover={true}
                    striped={false}
                    pointerOnHover={false}
                    dense={false}
                    persistTableHead={true}
                    noHeader={false}
                    fixedHeader={false}
                    fixedHeaderScrollHeight={'300px'}
                    progressPending={false}
                    noTableHead={false}
                    noContextMenu={false}
                    direction={Direction.AUTO}
                    subHeader={false}
                    subHeaderAlign={Alignment.RIGHT}
                    subHeaderWrap={true}
                    responsive={true}
                    disabled={false}
                    className="proteryReport"
                />
            </div>
        </div>
    );
};

export default Files;
