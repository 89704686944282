import React, { useEffect, useRef, useState } from 'react';
import styles from './select.module.css';

export function Select({ multiple, value, onChange, options }) {
    const [isOpen, setIsOpen] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    const [searchText, setSearchText] = useState('');
    const containerRef = useRef(null);

    function clearOptions() {
        multiple ? onChange([]) : onChange(undefined);
    }

    function selectOption(option) {
        if (multiple) {
            const newValue = Array.isArray(value) && value?.some((o) => o.value === option.value) ? Array.isArray(value) && value.filter((o) => o.value !== option.value) : [...value, option];
            onChange(newValue);
        } else {
            if (option.value !== value?.value) onChange(option);
        }
    }

    function isOptionSelected(option) {
        return multiple ? Array.isArray(value) && value?.some((selectedOption) => selectedOption.value === option.value) : option?.value === value?.value;
    }

    useEffect(() => {
        if (isOpen) setHighlightedIndex(0);
    }, [isOpen]);

    useEffect(() => {
        const handler = (e) => {
            if (e.target !== containerRef.current) return;
            switch (e.code) {
                case 'Enter':
                case 'Space':
                    setIsOpen((prev) => !prev);
                    if (isOpen) selectOption(options[highlightedIndex]);
                    break;
                case 'ArrowUp':
                case 'ArrowDown': {
                    if (!isOpen) {
                        setIsOpen(true);
                        break;
                    }

                    const newValue = highlightedIndex + (e.code === 'ArrowDown' ? 1 : -1);
                    if (newValue >= 0 && newValue < options.length) {
                        setHighlightedIndex(newValue);
                    }
                    break;
                }
                case 'Escape':
                    setIsOpen(false);
                    break;
            }
        };
        containerRef.current?.addEventListener('keydown', handler);

        return () => {
            containerRef.current?.removeEventListener('keydown', handler);
        };
    }, [isOpen, highlightedIndex, options]);

    const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(searchText.toLowerCase()));

    return (
        <div ref={containerRef} onBlur={() => setIsOpen(false)} onClick={() => setIsOpen((prev) => !prev)} tabIndex={0} className={styles.container}>
            <span className={styles.value}>
                {multiple ? (
                    value?.length ? (
                        value?.map((v) => (
                            <button
                                key={v.value}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    selectOption(v);
                                }}
                                className={styles['option-badge']}
                            >
                                {v.label}
                                <span className={styles['remove-btn']}>&times;</span>
                            </button>
                        ))
                    ) : (
                        <span className={styles.placeholder}>Select</span>
                    )
                ) : value?.label ? (
                    value?.label
                ) : (
                    <span className={styles.placeholder}>Select</span>
                )}
            </span>
            <div className={styles.caret}></div>
            <ul className={`${styles.options} ${isOpen ? styles.show : ''}`}>
                {filteredOptions.map((option, index) => (
                    <li
                        onClick={(e) => {
                            e.stopPropagation();
                            selectOption(option);
                            setIsOpen(false);
                        }}
                        onMouseEnter={() => setHighlightedIndex(index)}
                        key={option.value}
                        className={`${styles.option} ${isOptionSelected(option) ? styles.selected : ''} ${index === highlightedIndex ? styles.highlighted : ''}`}
                        disabled={isOptionSelected(option)} // Disable already selected options
                    >
                        {option.label}
                    </li>
                ))}
            </ul>
        </div>
    );
}
