import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Loader from '../loader/Loader';

const GoogleCalenderModal = ({ show, onHide, title, body, confirmText, cancelText, onConfirm, loading }) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className="confirm-title">{title}</span>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{body}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    {cancelText || 'Cancel'}
                </Button>
                <Button
                    variant="primary"
                    onClick={onConfirm}
                    style={{
                        minWidth: '80px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '38px'
                    }}
                >
                    {loading ? <Loader size="sm" /> : confirmText || 'Confirm'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default GoogleCalenderModal;
