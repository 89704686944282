import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="card">
                <div className="card-body">
                    <div className="footer-container">
                        <div className="footer-left">
                            <span>
                                If you have any queries, please feel free to{' '}
                                <Link target="_blank" to="https://remindhome.ropstam.dev/#contact_us_remind">
                                    <b>Contact us</b>
                                </Link>
                            </span>
                        </div>
                        <div className="footer-right">
                            <Link target="_blank" to="https://remindhome.ropstam.dev/terms-and-conditions/">
                                <b>Terms of Use</b>
                            </Link>
                            <Link target="_blank" to="https://remindhome.ropstam.dev/privacy-policy/">
                                <b>Privacy Policy</b>
                            </Link>
                            <Link target="_blank" to="https://remindhome.ropstam.dev/affiliate-agreement/">
                                <b>Affiliate Agreement</b>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
