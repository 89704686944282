import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { addSubmitReferalData, getDataReralCode } from '../../axiosCalls/seller'; // Fetch referral codes
import axios from 'axios';

const ReferNowPopup = (props) => {
    const [giftSubscriptionPopup, setGiftSubscriptionPopup] = useState(props.step);
    const [selectedOption, setSelectedOption] = useState('');
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        referralCode: ''
    });
    const [referralCodes, setReferralCodes] = useState([]);

    // Fetch referral codes when component mounts
    useEffect(() => {
        fetchReferralCodes();
    }, []);

    // Update the popup state if props.step changes
    useEffect(() => {
        setGiftSubscriptionPopup(props.step);
    }, [props.step]);

    // Fetch referral codes from API
    const fetchReferralCodes = async () => {
        try {
            const response = await getDataReralCode();
            setReferralCodes(response.data); // Assuming response has the list of referral codes
        } catch (error) {
            toast.error('Failed to load referral codes.');
        }
    };

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Validate email format
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate inputs
        const { firstName, lastName, email, referralCode } = formData;
        if (!firstName || !lastName || !email || !referralCode) {
            return toast.error('All fields are required.');
        }

        if (!isValidEmail(email)) {
            return toast.error('Please enter a valid email.');
        }

        // Prepare data for API call
        const payload = {
            homeowner_first_name: firstName,
            homeowner_last_name: lastName,
            homeowner_email: email,
            referral_code: referralCode,
            status: 'active'
        };

        try {
            const response = await addSubmitReferalData(payload);
            if (response.status === 200) {
                toast.success(response.data.message);
                fetchReferralCodes(); // Reload referral codes
                props.handlerClosePopupRefer(false); // Close the popup
            }
        } catch (error) {
            console.error('API Error:', error);
            toast.error('Failed to add referral.');
        }
    };

    // Close the popup and reset state
    const handlerClosePopup = () => {
        props.handlerClosePopupRefer(false);
        setGiftSubscriptionPopup('first');
    };

    return (
        <>
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter" className="GiftSubPopup">
                <Modal.Header closeButton>
                    <h3 className="gift-popup-center-heading">Refer remind</h3>
                </Modal.Header>
                <Modal.Body className="grid-example">
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3" controlId="firstName">
                                <Form.Label>Homeowner First Name</Form.Label>
                                <Form.Control type="text" placeholder="Mock" name="firstName" value={formData.firstName} onChange={handleInputChange} />
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3" controlId="lastName">
                                <Form.Label>Homeowner Last Name</Form.Label>
                                <Form.Control type="text" placeholder="Freddy" name="lastName" value={formData.lastName} onChange={handleInputChange} />
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3" controlId="email">
                                <Form.Label>Homeowner Email</Form.Label>
                                <Form.Control type="text" placeholder="mockkfreddy@gmail.com" name="email" value={formData.email} onChange={handleInputChange} />
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3" controlId="referralCode">
                                <Form.Label>Referral Code</Form.Label>
                                <Form.Select name="referralCode" value={formData.referralCode} onChange={handleInputChange}>
                                    <option value="">Select Referral Code</option>
                                    {/* {referralCodes
                                        .filter((item) => item.status === 'active')
                                        .map((item) => (
                                            <option key={item.id} value={item.referral_code}>
                                                {item.referral_code}
                                            </option>
                                        ))} */}
                                    {Array.isArray(referralCodes) &&
                                        referralCodes
                                            .filter((item) => item.status === 'active')
                                            .map((item) => (
                                                <option key={item.id} value={item.referral_code}>
                                                    {item.referral_code}
                                                </option>
                                            ))}
                                </Form.Select>
                            </Form.Group>
                        </Row>

                        <Modal.Footer>
                            <div className="giftPopup-modal-footer-btns">
                                <button className="footer-button-cancel" onClick={handlerClosePopup}>
                                    Cancel
                                </button>
                                <button type="submit" className="proccedd-btn">
                                    Send
                                </button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ReferNowPopup;
