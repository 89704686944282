import React, { useEffect, useState } from 'react';
import '../asstes/styles/dashboardStyles.css';
import { Link } from 'react-router-dom';
import warningIcon from '../asstes/dash-icons/warningIcon.png';
import icon1 from '../asstes/dash-icons/sidebar/dashIcon1.svg';
import google_calendar_icon from '../asstes/dash-icons/google_calendar_icon.svg';
import DashboardCalender from '../components/inc/dashboardCalender';
import RecentPurchases from '../components/inc/recentPurchases';
import RecentFiles from '../components/inc/recentFiles';
import ProjectsSideNav from '../components/inc/projectsSideNav';
import ProjectsSideNavNew from '../components/inc/projectsSideNavNew';
import HomeEssentialsSideNav from '../components/inc/homeEssentialsSideNav';
import PurchasesServicesNavAdd from '../components/inc/purchasesServicesNavAdd';
import FilesSideNavAdd from '../components/inc/filesSideNavAdd';
import HomeEssentialsSideNavView from '../components/inc/homeEssentialsSideNavView';
import ReminderSideNav from '../components/inc/reminderSideNav';
import { addFileApis, deleteHomeEssentials, disconnect_google_account, getHomeEsstentialList, getProjectsList, getPurchaseList, getServiceList, getTaxonomyById, updateHomeEssential, updateProjectApis } from '../../axiosCalls/userdashboard';
import { toast } from 'react-toastify';
import { isPropertyExist } from '../utils/helper';
import AddHomeEssential from '../components/add_home_essential/addHomeEssential';
import { driver } from 'driver.js';
import { entireDriver } from './driver/constant';
import QuickAction from '../../quick-action/QuickAction';
import ConfirmationModal from '../components/modals/ConfirmationModal';
import Loader from '../components/loader/Loader';
import OffCanvasBootstrap from '../../off_canvas/OffCanvasBootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as types from '../../redux/actions/actionTypes';
import GoogleCalenderModal from '../components/modals/GoogleCalenderModal';
const Dashboard = () => {
    const dispatch = useDispatch();
    const MainDriver = driver({
        onCloseClick: () => {
            localStorage.setItem('mainTour', JSON.stringify(true));
            MainDriver.destroy();
        },
        ...entireDriver
    });
    const [sideNavWidth, setSideNavWidth] = useState('sidenavPopuWidthHide');
    const [sideNavWidthOverlay, setsideNavWidthOverlay] = useState('0%');
    const [continueChange, setContinueChange] = useState('createStep');
    const [homeEssentialViewId, setHomeEssentialViewId] = useState([]);
    const [NewFileData, setNewFileData] = useState([]);
    const [prodLineItem, setProductLineItems] = useState([]);
    const [quickAddDetail, setQuickAddDetail] = useState(null);
    const [listProject, setListProjects] = useState([]);
    const [ViewValueForProject, setViewValueForProject] = useState([]);
    const [updatedProjectFormData, setUpdatedProjectFormData] = useState([]);
    const [updatedHomeEssentialsData, setUpdatedHomeEssentialsData] = useState([]);
    const [homeEssentials, setHomeEssentials] = useState([]);
    const [fetchhomEssentialsLoading, setFetchHomEssentialsLoading] = useState(false);
    const [deletehomessentialsLoading, setDeleteHomEssentialsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showConnectSyncModal, setShowConnectSyncModal] = useState(false);
    const [showDisconnectSyncModal, setShowDisconnectSyncModal] = useState(false);
    const [syncLoading, setSyncLoading] = useState(false);
    const [unSyncLoading, setunSyncLoading] = useState(false);
    const [isConnected, setIsConnected] = useState(true); // Track if the user is connected
    const [emptyForm, setEmptyForm] = useState('no'); // Track if the user is connected

    const [isSubmittedFiles, setIsSubmittedFiles] = useState(false);

    const [homeEssentialFormData, setHomeEssentialFormData] = useState({
        name: '',
        make: '',
        model_number: '',
        purchase_date: '',
        age_of_home_essential: '',
        notes: '',
        warranty: 'unchecked',
        type_id: '',
        customType: '',
        warranty_data: {
            name: '',
            number: '',
            startDate: '',
            endDate: '',
            notes: '',
            files: []
        },
        files: [],
        images: [],
        linked_reminders: []
    });

    const [showCreateHomeEssential, setShowCreateHomeEssential] = useState(false);
    const [showCreateReminder, setShowCreateReminder] = useState(false);
    const [showCreateProject, setShowCreateProject] = useState(false);
    const [showCreatePurchase, setShowCreatePurchase] = useState(false);
    const [showCreateService, setShowCreateService] = useState(false);
    const [showCreateFiles, setShowCreateFiles] = useState(false);

    const handlerCancelSideNav = () => {
        setSideNavWidth('sidenavPopuWidthHide');
        setsideNavWidthOverlay('0%');
        document.body.classList.remove('no-scroll');
        if (emptyForm == 'yes') {
            setEmptyForm('no');
        } else {
            setEmptyForm('yes');
        }
    };
    const handlerChangeStep = async () => {
        if (continueChange === 'viewStepHome') {
            setContinueChange('EditStepHome');
        } else if (continueChange === 'suggets') {
            setContinueChange('EditStep');
        }

        if (continueChange === 'EditStepHome') {
            handleUpdateHomeEssential();
        }

        if (continueChange === 'EditStep') {
            handleUpdateProject();
        }
    };

    const handlerPServicesSideNave = (activeItem, detail) => {
        setContinueChange('createStepPServices');
        setIsChecked(false);
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        document.body.classList.add('no-scroll');
        if (detail) {
            if (detail?.lineItems?.length) {
                const list = detail?.lineItems.map((ii) => {
                    return {
                        name: ii?.Type,
                        price: ii?.PRICE
                    };
                });
                setProductLineItems(list);
            }
            setQuickAddDetail(detail?.info);
        }
    };

    function HandlerViewHomeEsstional(id, title) {
        setHomeEssentialViewId(title);
        setContinueChange('viewStepHome');
        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        document.body.classList.add('no-scroll');
    }
    const [isChecked, setIsChecked] = useState(false); // State to hold checkbox value

    useEffect(() => {
        // Check if the access token exists in localStorage
        const accessToken = localStorage.getItem('access_token');

        if (accessToken && accessToken !== 'undefined') {
            setIsConnected(true);
        } else {
            setIsConnected(false);
        }
    }, []);

    useEffect(() => {
        const isMainTour = JSON.parse(localStorage.getItem('mainTour'));
        const user = JSON.parse(localStorage.getItem('user'));
        if (!isMainTour && !user.meta_data.mainTour) {
            MainDriver.drive();
        }
    }, []);

    const [propertyDetails, setPropertyDetails] = useState(null);

    // Fetch home essentials when the component is mounted
    const fetchHomeEssentials = async () => {
        try {
            setFetchHomEssentialsLoading(true);
            const response = await getHomeEsstentialList();
            if (response && response.data && response.data.items) {
                setHomeEssentials(response.data.items);
            } else {
                console.error('No home essential data found');
            }
        } catch (error) {
            console.error('Error fetching home essentials:', error);
        } finally {
            setFetchHomEssentialsLoading(false);
        }
    };

    useEffect(() => {
        fetchHomeEssentials();
    }, []);

    const [servicesLoading, setServicesLoading] = useState(false);
    const [servicesList, setServicesList] = useState([]);
    const [purchasesList, setPurchaseList] = useState([]);

    const fetchPurchasesAndServicesData = async () => {
        try {
            setServicesLoading(true);
            const res = await getPurchaseList();
            setPurchaseList(res.data); // Set the response data into state

            const resservice = await getServiceList();
            setServicesList(resservice.data);
        } catch (error) {
            console.error('Error fetching purchase data', error);
        } finally {
            setServicesLoading(false);
        }
    };
    useEffect(() => {
        fetchPurchasesAndServicesData();
    }, []);

    const isGetAllPurchaseServices = useSelector((state) => state.root?.isGetAllPurchaseServices);

    useEffect(() => {
        if (isGetAllPurchaseServices) {
            fetchPurchasesAndServicesData();
            dispatch({ type: types.ALL_RECORDS, data: { keyOfData: 'isGetAllPurchaseServices', data: false } });
        }
    }, [dispatch, isGetAllPurchaseServices]);

    const handleUpdateHomeEssential = async () => {
        const { name, model_number, make, purchase_date, age_of_home_essential, linked_reminders, notes, type, type_id, warranty, warranty_data, files, images, warrantyFiles } = updatedHomeEssentialsData;

        if (!type_id) {
            toast.error('Please select a Home Essential Type');
            return;
        }
        if (!purchase_date) {
            toast.error('Purchase Date is required');
            return;
        }

        const data = new FormData();
        data.append('type', type_id);
        if (name) data.append('name', name);
        if (make) data.append('make', make);
        if (model_number) data.append('model_number', model_number);
        if (age_of_home_essential) data.append('age_of_home_essential', age_of_home_essential);
        data.append('purchase_date', purchase_date);
        linked_reminders?.forEach((remind) => {
            const reminderData = JSON.stringify({
                id: remind.id,
                date: remind.start_date,
                frequency_id: remind.frequency_id,
                frequency: remind.frequency,
                reminder_frequency: remind.reminder_frequency,
                time: remind.time
            });
            data.append('linked_reminders[]', reminderData);
        });
        if (notes) data.append('notes', notes);
        if (warranty) data.append('warranty', warranty);

        if (warranty) {
            if (warranty_data[0]?.name) data.append('warranty_data[][name]', warranty_data[0]?.name);
            if (warranty_data[1]?.number) data.append('warranty_data[][number]', warranty_data[1]?.number);
            if (warranty_data[2]?.start_date) data.append('warranty_data[][start_date]', warranty_data[2]?.start_date);
            if (warranty_data[3]?.end_date) data.append('warranty_data[][end_date]', warranty_data[3]?.end_date);
            if (warranty_data[4]?.notes) data.append('warranty_data[][notes]', warranty_data[4]?.notes);
            if (warranty_data?.files) data.append('warranty_data[files]', warranty_data?.files);
            if (warrantyFiles) {
                warrantyFiles?.forEach((file) => data.append('warranty_data_files[]', file));
            }
        }
        if (files?.length) files?.forEach((file) => data.append('files[]', file));
        if (images?.length) images?.forEach((image) => data.append('images[]', image));
        if (warranty_data?.files?.length) warranty_data?.files?.forEach((image) => data.append('warranty_data[files][]', image));

        try {
            const response = await updateHomeEssential(data, updatedHomeEssentialsData.id);
            if (response.status == 200) {
                toast.success(response.data.message);
                fetchHomeEssentials();
                handlerCancelSideNav();
            }
            // Optionally, trigger an update to the parent component or redirect
        } catch (error) {
            console.error('Error updating home essential:', error);
        }
    };

    const [projectLoading, setProjectLoading] = useState(false);
    const handleGetProjectList = async () => {
        setProjectLoading(true);
        try {
            const res = await getProjectsList();
            if (res && res.data) {
                setListProjects(res.data); // Set the projects list to the state
            }
        } catch (error) {
            // handle error
        } finally {
            setProjectLoading(false);
        }
    };
    useEffect(() => {
        handleGetProjectList();
    }, []);

    const HandlerViewProject = (pro) => {
        setViewValueForProject(pro);
        setContinueChange('suggets');

        setSideNavWidth('sidenavPopuWidth');
        setsideNavWidthOverlay('100%');
        document.body.classList.add('no-scroll');
    };

    // Function to handle updating a project
    const handleUpdateProject = async () => {
        // Validation
        const { project_name, start_date, end_date, notes, linked_home_essentials, linked_services, linked_purchases, files, images } = updatedProjectFormData;

        // Check for required fields
        if (!project_name) {
            toast.error('Project Name is required');
            return;
        }
        if (!start_date) {
            toast.error('Start Date is required');
            return;
        }

        try {
            const data = new FormData();
            data.append('project_name', project_name || '');
            data.append('start_date', start_date || '');
            data.append('end_date', end_date || '');
            data.append('notes', notes || '');
            data.append('linked_home_essentials', JSON.stringify(linked_home_essentials));
            data.append('linked_services', JSON.stringify(linked_services));
            data.append('linked_purchases', JSON.stringify(linked_purchases));

            files?.forEach((file) => data.append('files[]', file));
            images?.forEach((image) => data.append('images[]', image));

            const res = await updateProjectApis(data, ViewValueForProject.ID);
            if (res && res.status === 200) {
                toast.success(res.data.message);
                handlerCancelSideNav();
            }
        } catch (error) {
            console.error('Error updating project:', error);
            toast.error('There was an error updating the project. Please try again.');
        }
    };

    const handleDelete = async () => {
        try {
            setDeleteHomEssentialsLoading(true);
            const res = await deleteHomeEssentials(homeEssentialViewId);
            setShowModal(false);
            if (res.status === 200) {
                fetchHomeEssentials();
                handlerCancelSideNav();
                toast.success(res?.data?.message || 'Deleted Successfully');
            }
        } catch (error) {
            toast.error('Something went wrong!');
        } finally {
            setDeleteHomEssentialsLoading(false);
        }
    };

    // property Data
    const [propertyCategoryName, setPropertyCategoryName] = useState(null);
    const [propertyTypeName, setPropertyTypeName] = useState(null);
    const [propertyStateName, setPropertyStateName] = useState(null);

    const propertyData = localStorage.getItem('selectedProperty');
    useEffect(() => {
        if (propertyData) {
            const parsedData = JSON.parse(propertyData);
            setPropertyDetails(parsedData);
            fetchTaxonomyDetails(parsedData);
        }

        const handleStorageChange = () => {
            const updatedPropertyData = localStorage.getItem('selectedProperty');
            if (updatedPropertyData) {
                const parsedData = JSON.parse(updatedPropertyData);
                setPropertyDetails(parsedData);
                fetchTaxonomyDetails(parsedData);
            }
        };

        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [propertyData]);

    const fetchTaxonomyDetails = async ({ category, type, state }) => {
        try {
            if (!category || !type || !state) {
                return;
            }
            const [categoryRes, typeRes, stateRes] = await Promise.all([getTaxonomyById('property-category', category), getTaxonomyById('property-type', type), getTaxonomyById('states', state)]);

            setPropertyCategoryName(categoryRes.data.name);
            setPropertyTypeName(typeRes.data.name);
            setPropertyStateName(stateRes.data.name);
        } catch (error) {
            console.error('Error fetching taxonomy details:', error);
        }
    };

    const handleConnectGoogleCalendar = async (name) => {
        if (name === 'sign-in') {
            setShowConnectSyncModal(true);

            // const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
            // const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
            // const scope = 'https://www.googleapis.com/auth/calendar';
            // const responseType = 'code';
            // const accessType = 'offline';
            // const approvalPrompt = 'force';

            // // Construct the Google OAuth2 URL
            // const googleAuthUrl = `https://accounts.google.com/o/oauth2/auth?scope=${encodeURIComponent(scope)}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=${responseType}&client_id=${clientId}&access_type=${accessType}&approval_prompt=${approvalPrompt}`;

            // // Redirect the user to Google login
            // window.location.href = googleAuthUrl;
        } else if (name === 'sign-out') {
            setShowDisconnectSyncModal(true);
            // Remove tokens from localStorage to sign out
            // localStorage.removeItem('access_token');
            // localStorage.removeItem('refresh_token');
            // setIsConnected(false); // Update state to show the "Connect" button again

            // await disconnect_google_account();
        }
    };
    const handleConnectModalConfirm = () => {
        // Proceed with the Google OAuth flow when the user confirms the sync
        try {
            setSyncLoading(true);
            const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
            const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
            const scope = 'https://www.googleapis.com/auth/calendar';
            const responseType = 'code';
            const accessType = 'offline';
            const approvalPrompt = 'force';

            // Construct the Google OAuth2 URL
            const googleAuthUrl = `https://accounts.google.com/o/oauth2/auth?scope=${encodeURIComponent(scope)}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=${responseType}&client_id=${clientId}&access_type=${accessType}&approval_prompt=${approvalPrompt}`;

            // Redirect the user to Google login
            window.location.href = googleAuthUrl;
        } catch (error) {
            // handle error
        } finally {
            setSyncLoading(false);
        }
    };

    const handleDisconnectModalConfirm = async () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        setIsConnected(false); // Update state to show the "Connect" button again

        try {
            setunSyncLoading(true);
            await disconnect_google_account();
            setShowDisconnectSyncModal(false);
        } catch (error) {
            // handle error
            toast.error('Something went wrong!');
        } finally {
            setunSyncLoading(false);
        }
    };
    const handleConnectModalClose = () => {
        setShowConnectSyncModal(false);
    };
    const handleDisonnectModalClose = () => {
        setShowDisconnectSyncModal(false);
    };

    return (
        <>
            {/* <!-- partial --> */}

            <div className="content-wrapper">
                <div className="row">
                    <div className="col-xl-12 grid-margin stretch-card flex-column">
                        <div className="row">
                            <div className="col-lg-3 col-md-12 col-sm-12 dashboardHeadings">
                                <h5
                                    className="mb-2 text-titlecase mb-4"
                                    dangerouslySetInnerHTML={{
                                        __html: propertyDetails && propertyDetails.title
                                    }}
                                ></h5>
                            </div>
                            <div className="col-lg-9 col-md-12 col-sm-12 d-flex text-top-subtext dashboardHeadings">
                                <div className="col-md-6 top-text-head">
                                    {propertyDetails && propertyDetails.address_1 && 'Address:'}
                                    <span className="top-text-normal">{propertyDetails && [propertyDetails.address_1, propertyDetails.address_2, propertyDetails.city, propertyStateName, propertyDetails.postal_code].filter(Boolean).join(', ')}</span>
                                </div>
                                {propertyCategoryName && (
                                    <div className="col-md-3 top-text-head">
                                        Category:
                                        <span className="top-text-normal">{propertyCategoryName}</span>
                                    </div>
                                )}
                                {propertyTypeName && (
                                    <div className="col-md-3 top-text-head">
                                        Type:
                                        <span className="top-text-normal">{propertyTypeName}</span>
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Home Essentials Board */}

                        <div className="row  dashboard-card-parent">
                            <div className="row card-actions-btns">
                                <div className="col-md-6 col-sm-6">
                                    <h2 className="card-heading">Home Essentials</h2>
                                </div>
                                <div className="col-md-6 col-sm-6 card-right-action">
                                    <Link to="/home-essentials" className="outline-yellow-button">
                                        View All
                                    </Link>
                                    <Link
                                        onClick={() => {
                                            setShowCreateHomeEssential(true);
                                        }}
                                        className="outline-primary-button"
                                    >
                                        Add
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#00487C" strokeLinecap="round" />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                            <div className="row">
                                {fetchhomEssentialsLoading ? (
                                    <Loader />
                                ) : !homeEssentials.length ? (
                                    <AddHomeEssential
                                        heading={'Add home essential'}
                                        onClick={() => {
                                            if (isPropertyExist()) {
                                                setShowCreateHomeEssential(true);
                                            } else {
                                                toast.error('Please create property first');
                                            }
                                        }}
                                    />
                                ) : (
                                    homeEssentials
                                        .filter((_, index) => index <= 5) // Filter items with index <= 5
                                        .map((card, index) => (
                                            <div className="col-lg-2 col-md-4 col-sm-6 item_each_home_cards" id={index} key={index} onClick={() => HandlerViewHomeEsstional(index, card.id)}>
                                                <div className="dashboard-card">
                                                    <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                                                        <div className="justify-content-between align-items-center">
                                                            <div className="icon-box-card">
                                                                <img style={{ width: '32px', height: '32px' }} src={`${card.icon_url || icon1}`} alt={card.model_number} />
                                                                {card.warranty && (
                                                                    <div className="card-warining">
                                                                        <img alt="Warning" src={warningIcon} />
                                                                        <span>Warning</span>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <p
                                                                className="dashboard-heading"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: card.name
                                                                }}
                                                            ></p>
                                                            <p className="dashboard-subheading">{card.type}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                )}
                            </div>
                        </div>

                        {/* caldender board  */}
                        <div className="row dashboard-card-parent">
                            <div className="row card-actions-btns">
                                <div className="col-12 col-md-6">
                                    <h2 className="card-heading">Upcoming Reminders</h2>
                                </div>
                                <div className="col-12 col-md-6 card-right-action d-flex flex-wrap justify-content-md-end">
                                    {isConnected ? (
                                        // Show "Disconnect" button if user is connected
                                        <button className="outline_reminder reminder-active" id="google-calendar" onClick={() => handleConnectGoogleCalendar('sign-out')}>
                                            <img src={google_calendar_icon} width="25px" alt="disconnect" />
                                            Disconnect From Google Calendar
                                        </button>
                                    ) : (
                                        // Show "Connect" button if user is not connected
                                        <button className="outline_reminder mb-2 " id="google-calendar" onClick={() => handleConnectGoogleCalendar('sign-in')}>
                                            <img src={google_calendar_icon} width="25px" alt="connect" />
                                            Connect to Google Calendar
                                        </button>
                                    )}
                                    <Link to="/reminders" className=" outline-yellow-button  ">
                                        View All
                                    </Link>
                                    <Link
                                        onClick={() => {
                                            if (isPropertyExist()) {
                                                setShowCreateReminder(true);
                                            } else {
                                                toast.error('Please create property first');
                                            }
                                        }}
                                        className="outline-primary-button mb-2"
                                    >
                                        Add
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#00487C" strokeLinecap="round" />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <DashboardCalender />
                                </div>
                            </div>
                        </div>

                        <div className="row  dashboard-card-parent">
                            <div className="row card-actions-btns">
                                <div className="col-md-6 col-sm-6">
                                    <h2 className="card-heading">Projects</h2>
                                </div>
                                <div className="col-md-6 col-sm-6 card-right-action">
                                    <Link to="/projects" className="outline-yellow-button">
                                        View All
                                    </Link>
                                    <Link
                                        onClick={() => {
                                            if (isPropertyExist()) {
                                                setShowCreateProject(true);
                                            } else {
                                                toast.error('Please create property first');
                                            }
                                        }}
                                        className="outline-primary-button"
                                    >
                                        Add
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#00487C" strokeLinecap="round" />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                            <div className="row paddingRight0">
                                {!listProject.length ? (
                                    <AddHomeEssential
                                        heading={'Add projects'}
                                        onClick={() => {
                                            if (isPropertyExist()) {
                                                setShowCreateProject(true);
                                            } else {
                                                toast.error('Please create property first');
                                            }
                                        }}
                                    />
                                ) : null}
                                {projectLoading ? (
                                    <Loader />
                                ) : (
                                    listProject
                                        .filter((_, index) => index < 4)
                                        .map((pro, index) => (
                                            <div className="col-lg-3 col-md-4 col-sm-6 mb-3 item_each_home_cards" id={index} key={index} onClick={() => HandlerViewProject(pro)}>
                                                <div className="dashboard-card">
                                                    <div className="dashboard-card-card-body d-flex flex-column justify-content-between">
                                                        <div className="justify-content-between align-items-center">
                                                            <div className="icon-box-card"></div>
                                                            <p
                                                                className="projects dashboard-heading"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: pro.title
                                                                }}
                                                            ></p>
                                                            <p className="dashboard-subheading row ProjectService">
                                                                {JSON.parse(pro.linked_home_essentials).map((ser, Childindex) => (
                                                                    <span
                                                                        className={'childProjectService services-badge type-red'}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: ser.label
                                                                        }}
                                                                        key={Childindex}
                                                                    ></span>
                                                                ))}
                                                                {JSON.parse(pro.linked_services).map((ser, Childindex) => (
                                                                    <span
                                                                        className={'childProjectService services-badge type-camel'}
                                                                        key={Childindex}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: ser.label
                                                                        }}
                                                                    ></span>
                                                                ))}
                                                                {JSON.parse(pro.linked_purchases).map((ser, Childindex) => (
                                                                    <span
                                                                        className={'childProjectService services-badge type-green'}
                                                                        key={Childindex}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: ser.label
                                                                        }}
                                                                    ></span>
                                                                ))}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-footer">Project Cost: ${pro.cost}</div>
                                            </div>
                                        ))
                                )}
                            </div>
                        </div>

                        <div className="row dashboard-parent-recents recent_files-action">
                            {/* Recent Purchases */}
                            <div className=" col-md-6 dashboard-card-parent recentPurchasesMobile">
                                <div className="row card-actions-btns">
                                    <div className="col-md-6">
                                        <h2 className="card-heading"> Purchases and Services</h2>
                                    </div>
                                    <div className="col-md-6 card-right-action">
                                        <Link to="/purchases-services" className="outline-yellow-button">
                                            View All
                                        </Link>
                                        <Link
                                            onClick={() => {
                                                if (isPropertyExist()) {
                                                    setShowCreateService(true);
                                                } else {
                                                    toast.error('Please create property first');
                                                }
                                            }}
                                            className="outline-primary-button"
                                        >
                                            Add
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#00487C" strokeLinecap="round" />
                                            </svg>
                                        </Link>
                                    </div>
                                </div>

                                <div className="row p-0">
                                    {servicesLoading ? (
                                        <Loader />
                                    ) : (
                                        <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                                            <RecentPurchases serviceData={servicesList} purchaseData={purchasesList} fetchPurchasesAndServicesData={fetchPurchasesAndServicesData} />
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* Recent files */}
                            <div className=" col-md-6 dashboard-card-parent recent_files-action">
                                <div className="row card-actions-btns">
                                    <div className="col-md-6">
                                        <h2 className="card-heading">Recent Files</h2>
                                    </div>
                                    <div className="col-md-6 card-right-action">
                                        <Link to="/files" className="outline-yellow-button">
                                            View All
                                        </Link>
                                        <Link
                                            onClick={() => {
                                                if (isPropertyExist()) {
                                                    setShowCreateFiles(true);
                                                } else {
                                                    toast.error('Please create property first');
                                                }
                                            }}
                                            className="outline-primary-button"
                                        >
                                            Add
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M8.00065 3.33398V12.6673M3.33398 8.00065H12.6673" stroke="#00487C" strokeLinecap="round" />
                                            </svg>
                                        </Link>
                                    </div>
                                </div>
                                <div className="row p-0">
                                    <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                                        <RecentFiles />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div onClick={handlerCancelSideNav} style={{ width: sideNavWidthOverlay }} className="sidenav-overlay"></div>
                {isChecked ? (
                    <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>
                        <HomeEssentialsSideNav fetchHomeEssentials={fetchHomeEssentials} handlerCancelSideNav={handlerCancelSideNav} formData={homeEssentialFormData} />
                    </div>
                ) : (
                    <div id="mySidenav" className={`sidenav ${sideNavWidth}`}>
                        {continueChange === 'suggets' && (
                            <ProjectsSideNav
                                handleClose={() => {
                                    setContinueChange('');
                                    handlerCancelSideNav();
                                }}
                                handleGetProjectList={handleGetProjectList}
                                propsValue={ViewValueForProject}
                            />
                        )}
                        {continueChange === 'viewStepHome' && (
                            <HomeEssentialsSideNavView
                                handleClose={() => {
                                    setContinueChange('');
                                    handlerCancelSideNav();
                                }}
                                fetchHomeEssentials={fetchHomeEssentials}
                                propsValue={homeEssentialViewId}
                            />
                        )}
                    </div>
                )}
            </div>
            <OffCanvasBootstrap
                placement="end"
                name="end"
                show={showCreateHomeEssential}
                handleClose={() => {
                    setShowCreateHomeEssential(false);
                }}
            >
                <HomeEssentialsSideNav
                    fetchHomeEssentials={fetchHomeEssentials}
                    handlerCancelSideNav={() => {
                        setShowCreateHomeEssential(false);
                    }}
                    formData={homeEssentialFormData}
                />
            </OffCanvasBootstrap>
            <OffCanvasBootstrap
                placement="end"
                name="end"
                closeButton
                show={showCreateReminder}
                handleClose={() => {
                    setShowCreateReminder(false);
                }}
            >
                <ReminderSideNav />
            </OffCanvasBootstrap>
            <OffCanvasBootstrap
                placement="end"
                name="end"
                show={showCreateProject}
                handleClose={() => {
                    setShowCreateProject(false);
                }}
            >
                <ProjectsSideNavNew
                    handleClose={() => {
                        setShowCreateProject(false);
                    }}
                    handleGetProjectList={handleGetProjectList}
                />
            </OffCanvasBootstrap>
            <OffCanvasBootstrap
                placement="end"
                name="end"
                show={showCreateFiles}
                handleClose={() => {
                    setShowCreateFiles(false);
                }}
            >
                <FilesSideNavAdd
                    handleClose={() => {
                        setShowCreateFiles(false);
                    }}
                />
            </OffCanvasBootstrap>
            <OffCanvasBootstrap
                placement="end"
                name="end"
                show={showCreatePurchase || showCreateService}
                handleClose={() => {
                    setShowCreatePurchase(false);
                    setShowCreateService(false);
                }}
            >
                <PurchasesServicesNavAdd
                    handleClose={() => {
                        setShowCreatePurchase(false);
                        setShowCreateService(false);
                    }}
                    fetchPurchasesAndServicesData={fetchPurchasesAndServicesData}
                />
            </OffCanvasBootstrap>

            <QuickAction
                sidebarInfoFunc={(activeItem, detail) => {
                    if (activeItem) {
                        switch (activeItem) {
                            case 'HOME_ESSENTIAL':
                                setShowCreateHomeEssential(true);
                                break;
                            case 'REMINDERS':
                                setShowCreateReminder(true);
                                break;
                            case 'PROJECTS':
                                setShowCreateProject(true);
                                break;
                            case 'FILES':
                                setShowCreateFiles(true);
                                break;
                            default:
                                handlerPServicesSideNave(activeItem, detail);
                                break;
                        }
                    }
                }}
            />

            <GoogleCalenderModal
                show={showConnectSyncModal}
                onHide={handleConnectModalClose}
                title="Confirm Sync"
                body="Sync your remind Home Solutions notifications to your Google Calendar for seamless organization and scheduling."
                confirmText="Confirm"
                cancelText="Cancel"
                onConfirm={handleConnectModalConfirm}
                loading={syncLoading}
            />

            <GoogleCalenderModal
                show={showDisconnectSyncModal}
                onHide={handleDisonnectModalClose}
                title="Confirm UnSync"
                body="Notifications will no longer be shared with your Google Calendar."
                confirmText="Confirm"
                cancelText="Cancel"
                onConfirm={handleDisconnectModalConfirm}
                loading={unSyncLoading}
            />

            <ConfirmationModal show={showModal} onHide={() => setShowModal(false)} onConfirm={handleDelete} title="Confirm Deletion" body="Are you sure you want to delete this item?" loading={deletehomessentialsLoading} />
        </>
    );
};

export default Dashboard;
