import React, { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import placeholderImage from '../../asstes/dash-icons/placeholderImage.svg';
import ListOfFIlesForEssemtialNav from './ListOfFIlesForEssemtialNav';
import LinkReminders from './linkReminders';
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';
import eyeIcon from '../../asstes/dash-icons/eyeIcon.svg';
import downloadIcon from '../../asstes/dash-icons/downloadIcon.svg';
import OffCanvasBootstrap from '../../../off_canvas/OffCanvasBootstrap';
import ProjectsSideNavNew from './projectsSideNavNew';
import { deleteProjectApis, getProjectById } from '../../../axiosCalls/userdashboard';
import Loader from '../loader/Loader';
import { confirmDelete } from '../../utils/helper';
import { toast } from 'react-toastify';

const ProjectsSideNav = (props) => {
    const { propsValue, handleClose, handleGetProjectList } = props;
    const [show, setShow] = useState(false);
    const [project, setProject] = useState({});

    // loader
    const [loader, setLoader] = useState(false);

    const fetchProjectById = async () => {
        try {
            setLoader(true);
            const resp = await getProjectById(propsValue?.ID);
            if (resp.status === 200) {
                setProject(resp.data);
            }
        } catch (error) {
            // handle error
        } finally {
            setLoader(false);
        }
    };
    useEffect(() => {
        fetchProjectById();
    }, [propsValue]);

    const [showFileModal, setShowFileModal] = useState(false);
    const [fileToView, setFileToView] = useState(null);

    // Parse the linked services, purchases, and home essentials from JSON string
    const linkedPurchases = JSON.parse(project.linked_purchases || '[]');
    const linkedServices = JSON.parse(project.linked_services || '[]');
    const linkedHomeEssentials = JSON.parse(project.linked_home_essentials || '[]');
    // Function to handle file viewing
    const handleViewFile = (fileUrl) => {
        setFileToView(fileUrl);
        setShowFileModal(true);
    };

    // Function to handle file download
    const handleDownloadFile = (fileUrl) => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', fileUrl.split('/').pop()); // Set the download attribute with the file name
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link); // Remove the link after download
    };
    if (loader) {
        return <Loader />;
    }
    return (
        <>
            <div className="side-nav">
                <div className="side-header">
                    <h3>{project.title}</h3>
                    <h5>
                        {project.start_date} - {project.end_date}
                    </h5>
                </div>

                <div className="side-body">
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label>Project Cost</Form.Label>
                                <div className="link-table-main project-cost-view">
                                    <span className="text-paragraph mt-0">{project.cost?.toLocaleString()}</span>
                                    <span className="text-paragraph mt-0">
                                        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6.5 6.5L5.5 6.5L5.5 2.5L6 2.5C6.53043 2.5 7.03914 2.71072 7.41421 3.08579C7.78929 3.46086 8 3.96957 8 4.5C8 4.63261 8.05268 4.75979 8.14644 4.85356C8.24021 4.94732 8.36739 5 8.5 5C8.63261 5 8.75978 4.94732 8.85355 4.85356C8.94732 4.75979 9 4.63261 9 4.5C8.99917 3.70461 8.68284 2.94203 8.12041 2.3796C7.55798 1.81717 6.79539 1.50083 6 1.5L5.5 1.5L5.5 0.500001C5.5 0.367393 5.44732 0.240216 5.35355 0.146448C5.25979 0.0526796 5.13261 1.21499e-06 5 1.18021e-06C4.86739 1.14543e-06 4.74021 0.0526795 4.64645 0.146448C4.55268 0.240216 4.5 0.367393 4.5 0.500001L4.5 1.5L4 1.5C3.20435 1.5 2.44129 1.81607 1.87868 2.37868C1.31607 2.94129 0.999999 3.70435 0.999999 4.5C0.999999 5.29565 1.31607 6.05871 1.87868 6.62132C2.44129 7.18393 3.20435 7.5 4 7.5L4.5 7.5L4.5 11.5L3.5 11.5C2.96956 11.5 2.46086 11.2893 2.08578 10.9142C1.71071 10.5391 1.5 10.0304 1.5 9.5C1.5 9.36739 1.44732 9.24021 1.35355 9.14645C1.25978 9.05268 1.13261 9 0.999998 9C0.867389 9 0.740213 9.05268 0.646444 9.14645C0.552676 9.24021 0.499998 9.36739 0.499998 9.5C0.500825 10.2954 0.817161 11.058 1.37959 11.6204C1.94202 12.1828 2.7046 12.4992 3.5 12.5L4.5 12.5L4.5 13.5C4.5 13.6326 4.55267 13.7598 4.64644 13.8536C4.74021 13.9473 4.86739 14 5 14C5.1326 14 5.25978 13.9473 5.35355 13.8536C5.44732 13.7598 5.5 13.6326 5.5 13.5L5.5 12.5L6.5 12.5C7.29565 12.5 8.05871 12.1839 8.62132 11.6213C9.18393 11.0587 9.5 10.2957 9.5 9.5C9.5 8.70435 9.18393 7.94129 8.62132 7.37868C8.05871 6.81607 7.29565 6.5 6.5 6.5ZM4 6.5C3.46957 6.5 2.96086 6.28929 2.58578 5.91421C2.21071 5.53914 2 5.03043 2 4.5C2 3.96957 2.21071 3.46086 2.58579 3.08579C2.96086 2.71071 3.46957 2.5 4 2.5L4.5 2.5L4.5 6.5L4 6.5ZM6.5 11.5L5.5 11.5L5.5 7.5L6.5 7.5C7.03043 7.5 7.53914 7.71072 7.91421 8.08579C8.28928 8.46086 8.5 8.96957 8.5 9.5C8.5 10.0304 8.28928 10.5391 7.91421 10.9142C7.53914 11.2893 7.03043 11.5 6.5 11.5Z"
                                                fill="#535860"
                                            />
                                        </svg>
                                    </span>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Files</Form.Label>
                                    <div className="listofFiles">
                                        {project.files?.map((file, index) => (
                                            <div className="warrantyFile" key={index}>
                                                <div className="text">
                                                    <img src={pdfIcon} alt="pdfIcon" />
                                                    {file.split('/').pop()}
                                                </div>
                                                <div className="actions">
                                                    <span
                                                        onClick={() => handleViewFile(file)}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <img src={eyeIcon} alt="View" />
                                                    </span>
                                                    <span
                                                        onClick={() => handleDownloadFile(file)}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                    >
                                                        <img src={downloadIcon} alt="Download" />
                                                    </span>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Images</Form.Label>
                                    <div className="listOfImages">
                                        {project.images && project.images.length > 0 ? (
                                            project.images?.map((img, idx) => (
                                                <a href={img} target="_blank" rel="noreferrer">
                                                    <div key={idx}>
                                                        <img src={img} alt={`Project Image ${idx + 1}`} />
                                                    </div>
                                                </a>
                                            ))
                                        ) : (
                                            <div>
                                                <img src={placeholderImage} alt="placeholder" />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Linked Purchases</Form.Label>
                                    <div className="listOfImages">
                                        {linkedPurchases?.map((purchase, idx) => (
                                            <span key={idx} className="services-badge type-green">
                                                {purchase.label}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Linked Services</Form.Label>
                                    <div className="listOfImages">
                                        {linkedServices?.map((service, idx) => (
                                            <span key={idx} className="services-badge type-camel">
                                                {service.label}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Linked Home Essentials</Form.Label>
                                    <div className="listOfImages">
                                        {linkedHomeEssentials?.map((essential, idx) => (
                                            <span key={idx} className="services-badge type-red">
                                                {essential.label}
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <div className="link-table-main">
                                    <Form.Label>Notes</Form.Label>
                                    <p className="text-paragraph">{project.notes || 'No notes available'}</p>
                                </div>
                            </Form.Group>
                        </Row>
                    </Form>

                    {/* Modal to View File */}
                    <Modal show={showFileModal} onHide={() => setShowFileModal(false)} size="lg" centered>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>{fileToView && fileToView.endsWith('.pdf') ? <iframe src={fileToView} style={{ width: '100%', height: '500px' }} frameBorder="0" title="PDF Viewer" /> : <img src={fileToView} alt="File" style={{ width: '100%' }} />}</Modal.Body>
                        <Modal.Footer></Modal.Footer>
                    </Modal>
                </div>
                <div className="side-footer form-actions d-flex justify-content-between gap-2">
                    <Button
                        className="button-cancel"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        Cancel
                    </Button>
                    <div className="d-flex gap-2">
                        <Button
                            className="delete-btn"
                            onClick={() => {
                                confirmDelete(async (result) => {
                                    if (result.isConfirmed) {
                                        const resp = await deleteProjectApis(project.ID);
                                        if (resp.status === 200) {
                                            handleClose();
                                            handleGetProjectList();
                                            toast.success('Project deleted successfully');
                                        }
                                    }
                                });
                            }}
                        >
                            Delete
                        </Button>
                        <Button
                            onClick={() => {
                                setShow(true);
                            }}
                            className="button-primary"
                            type="button"
                        >
                            Edit
                        </Button>
                    </div>
                </div>
            </div>
            <OffCanvasBootstrap
                placement="end"
                name="end"
                show={show}
                handleClose={() => {
                    setShow(false);
                }}
            >
                <ProjectsSideNavNew
                    handleClose={() => {
                        setShow(false);
                    }}
                    project={project}
                    handleGetProjectList={handleGetProjectList}
                    fetchProjectById={fetchProjectById}
                />
            </OffCanvasBootstrap>
        </>
    );
};

export default ProjectsSideNav;
