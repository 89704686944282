import React from 'react';
import DataTable, { Alignment, Direction } from 'react-data-table-component';

const PropertyWarranties = () => {
    const columnAPropertyWarranty = [
        {
            name: 'Warranty',
            selector: (row) => row.Warranty,
            sortable: true,
            reorder: true
        },
        {
            name: 'Start date',
            selector: (row) => row.Startdate,
            sortable: true,
            reorder: true
        },
        {
            name: 'End date',
            selector: (row) => row.Enddate,
            sortable: true,
            reorder: true
        },
        {
            name: 'Note',
            selector: (row) => row.Note,
            sortable: true,
            reorder: true
        },
        {
            name: 'File name',
            selector: (row) => row.Filename,
            sortable: true,
            reorder: true
        },
        {
            name: 'Purchase/Service',
            selector: (row) => row.PurchaseService,
            sortable: true,
            reorder: true
        }
    ];
    const dataAPropertyWarranty = [
        {
            id: 1,
            Warranty: 'Thoreau Reflector',
            Startdate: '04/01/2023',
            Enddate: '04/01/2023',
            Note: 'EIfkefefefe',
            Filename: 'document name.png',
            PurchaseService: 'Service Name'
        },
        {
            id: 1,
            Warranty: 'Thoreau Reflector',
            Startdate: '04/01/2023',
            Enddate: '04/01/2023',
            Note: 'EIfkefefefe',
            Filename: 'document name.png',
            PurchaseService: 'Service Name'
        },
        {
            id: 1,
            Warranty: 'Thoreau Reflector',
            Startdate: '04/01/2023',
            Enddate: '04/01/2023',
            Note: 'EIfkefefefe',
            Filename: 'document name.png',
            PurchaseService: 'Service Name'
        },
        {
            id: 1,
            Warranty: 'Thoreau Reflector',
            Startdate: '04/01/2023',
            Enddate: '04/01/2023',
            Note: 'EIfkefefefe',
            Filename: 'document name.png',
            PurchaseService: 'Service Name'
        },
        {
            id: 1,
            Warranty: 'Thoreau Reflector',
            Startdate: '04/01/2023',
            Enddate: '04/01/2023',
            Note: 'EIfkefefefe',
            Filename: 'document name.png',
            PurchaseService: 'Service Name'
        },
        {
            id: 1,
            Warranty: 'Thoreau Reflector',
            Startdate: '04/01/2023',
            Enddate: '04/01/2023',
            Note: 'EIfkefefefe',
            Filename: 'document name.png',
            PurchaseService: 'Service Name'
        }
    ];
    return (
        <div className="col-md-12 mb-3" id="property-warranty">
            <label className="reportSectiontitile">Property Warranties</label>
            <div className="recentPurcheseTable">
                <DataTable
                    columns={columnAPropertyWarranty}
                    data={dataAPropertyWarranty}
                    defaultSortFieldId={2}
                    selectableRows={false}
                    selectableRowsNoSelectAll={false}
                    selectableRowsVisibleOnly={false}
                    selectableRowsHighlight={false}
                    selectableRowsSingle={false}
                    expandableRows={false}
                    expandOnRowClicked={false}
                    expandOnRowDoubleClicked={false}
                    expandableRowsHideExpander={false}
                    pagination={false}
                    highlightOnHover={true}
                    striped={false}
                    pointerOnHover={false}
                    dense={false}
                    persistTableHead={true}
                    noHeader={false}
                    fixedHeader={false}
                    fixedHeaderScrollHeight={'300px'}
                    progressPending={false}
                    noTableHead={false}
                    noContextMenu={false}
                    direction={Direction.AUTO}
                    subHeader={false}
                    subHeaderAlign={Alignment.RIGHT}
                    subHeaderWrap={true}
                    responsive={true}
                    disabled={false}
                    className="proteryReport"
                />
            </div>
        </div>
    );
};

export default PropertyWarranties;
