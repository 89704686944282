import React, { useState } from 'react';
import '../assets/REmindSellerProgramGraphStyles.css';
import prograssCruve from '../assets/images/progressCarve.svg';
import progressCarveMobile from '../assets/images/progressCarveMobile.svg';
import startingPoint from '../assets/images/startingPoint.svg';
import giftCollected from '../assets/images/giftCollected.svg';
import giftIcon from '../assets/images/giftIcon.svg';
import finalgiftIcon from '../assets/images/finalGift.svg';
import { Link } from 'react-router-dom';
import swtichIcon from '../assets/icons/refresh-cw.svg';

const REmindSellerProgramGraph = () => {
    const [selectedHotspot, setSelectedHotspot] = useState(null);
    // State to track the active button
    const [activeButton, setActiveButton] = useState('giftbtn'); // Assuming 'giftbtn' is initially active

    // Function to handle button click
    const handleClick = (button) => {
        setActiveButton(button);
    };
    const selectHotspot = (index) => {
        setSelectedHotspot(selectedHotspot === index ? null : index);
    };

    const hotspots = [
        { src: startingPoint, tooltipType: false, tooltip: 'Collect $125 Bonus!', position: 'lg-hotspot--top-left starting-point', before: 'Gifted Subscription', after: 'Rewards', beforetop: '-20px', aftertop: '40px', Beforeleft: '-30px', Afterleft: '-10px' },
        { src: giftCollected, tooltipType: false, tooltip: 'Collect $81 Bonus!', position: 'lg-hotspot--top-right middle-dot', before: '10', after: 'Reward Collected', beforetop: '0px', aftertop: '60px', Beforeleft: '35px', Afterleft: '0px' },
        { src: giftIcon, tooltipType: true, tooltip: 'Collect $125 Bonus!', position: 'lg-hotspot--top-right third-dot', before: '25', after: '$250', beforetop: '-20px', aftertop: '40px', Beforeleft: '15px', Afterleft: '10px' },

        { src: giftIcon, tooltipType: true, tooltip: 'Collect $100 Bonus!', position: 'lg-hotspot--bottom-left second-row-third-dot', before: '50', after: '$250', beforetop: '-20px', aftertop: '40px', Beforeleft: '15px', Afterleft: '10px' },
        { src: giftIcon, tooltipType: true, tooltip: 'Collect $125 Bonus!', position: 'lg-hotspot--bottom-left second-row-sec-dot', before: '100', after: '$500', beforetop: '-20px', aftertop: '40px', Beforeleft: '13px', Afterleft: '7px' },
        { src: giftIcon, tooltipType: true, tooltip: 'Collect $85 Bonus!', position: 'lg-hotspot--bottom-left second-row', before: '250', after: '$1,250', beforetop: '10px', aftertop: '10px', Beforeleft: '-25px', Afterleft: '45px' },

        { src: giftIcon, tooltipType: true, tooltip: 'Collect $40 Bonus!', position: 'lg-hotspot--bottom-right third-row-middle', before: '500', after: '$2,500', beforetop: '-20px', aftertop: '40px', Beforeleft: '10px', Afterleft: '5px' },
        { src: giftIcon, tooltipType: true, tooltip: 'Collect $81 Bonus!', position: 'lg-hotspot--bottom-right third-row-last', before: '1,000', after: '$5,000', beforetop: '-20px', aftertop: '40px', Beforeleft: '8px', Afterleft: '5px' },
        { src: giftIcon, tooltipType: true, tooltip: 'Collect $181 Bonus!', position: 'lg-hotspot--bottom-right forth-row-third', before: '5,000', after: '$12,500', beforetop: '-20px', aftertop: '40px', Beforeleft: '5px', Afterleft: '0px' },
        { src: giftIcon, tooltipType: true, tooltip: 'Collect $670 Bonus!', position: 'lg-hotspot--bottom-right forth-row-sec', before: '10,000', after: '$25,000', beforetop: '-20px', aftertop: '40px', Beforeleft: '5px', Afterleft: '0px' },
        {
            src: finalgiftIcon,
            tooltipType: true,
            tooltip: 'Collect $128 Bonus!',
            position: 'lg-hotspot--bottom-right forth-row-first',
            className: 'img-big-gift',
            tooltipClass: 'tooltip-big-gift',
            before: '25000',
            after: '$50,000',
            beforetop: '-20px',
            aftertop: '70px',
            Beforeleft: '25px',
            Afterleft: '20px'
        }
    ];
    // mobile layout
    const MobileHotspots = [
        { src: startingPoint, tooltipType: false, tooltip: 'Collect $125 Bonus!', position: 'lg-hotspot--top-left starting-point', before: 'Gifted Subscription', after: 'Rewards', beforetop: '-30px', aftertop: '35px', Beforeleft: '-25px', Afterleft: '-10px' },
        { src: giftCollected, tooltipType: false, tooltip: 'Collect $81 Bonus!', position: 'lg-hotspot--top-right middle-dot', before: '10', after: 'Reward Collected', beforetop: '0px', aftertop: '60px', Beforeleft: '35px', Afterleft: '0px' },
        { src: giftIcon, tooltipType: true, tooltip: 'Collect $125 Bonus!', position: 'lg-hotspot--top-right third-dot', before: '25', after: '$250', beforetop: '-20px', aftertop: '40px', Beforeleft: '15px', Afterleft: '10px' },

        { src: giftIcon, tooltipType: true, tooltip: 'Collect $100 Bonus!', position: 'lg-hotspot--bottom-left second-row-third-dot', before: '50', after: '$250', beforetop: '12px', aftertop: '12px', Beforeleft: '-15px', Afterleft: '45px' },
        { src: giftIcon, tooltipType: true, tooltip: 'Collect $125 Bonus!', position: 'lg-hotspot--bottom-left second-row-sec-dot', before: '100', after: '$500', beforetop: '-20px', aftertop: '40px', Beforeleft: '13px', Afterleft: '7px' },
        { src: giftIcon, tooltipType: true, tooltip: 'Collect $85 Bonus!', position: 'lg-hotspot--bottom-left second-row', before: '250', after: '$1,250', beforetop: '-20px', aftertop: '40px', Beforeleft: '10px', Afterleft: '0px' },

        { src: giftIcon, tooltipType: true, tooltip: 'Collect $40 Bonus!', position: 'lg-hotspot--bottom-right third-row-middle', before: '500', after: '$2,500', beforetop: '13px', aftertop: '12px', Beforeleft: '-20px', Afterleft: '40px' },
        { src: giftIcon, tooltipType: true, tooltip: 'Collect $81 Bonus!', position: 'lg-hotspot--bottom-right third-row-last', before: '1,000', after: '$5,000', beforetop: '-20px', aftertop: '40px', Beforeleft: '8px', Afterleft: '5px' },
        { src: giftIcon, tooltipType: true, tooltip: 'Collect $181 Bonus!', position: 'lg-hotspot--bottom-right forth-row-third', before: '5,000', after: '$12,500', beforetop: '-20px', aftertop: '40px', Beforeleft: '5px', Afterleft: '0px' },
        { src: giftIcon, tooltipType: true, tooltip: 'Collect $670 Bonus!', position: 'lg-hotspot--bottom-right forth-row-sec', before: '10,000', after: '$25,000', beforetop: '12px', aftertop: '12px', Beforeleft: '-35px', Afterleft: '40px' },
        {
            src: finalgiftIcon,
            tooltipType: true,
            tooltip: 'Collect $128 Bonus!',
            position: 'lg-hotspot--bottom-right forth-row-first',
            className: 'img-big-gift',
            tooltipClass: 'tooltip-big-gift',
            before: '25000',
            after: '$50,000',
            beforetop: '-20px',
            aftertop: '70px',
            Beforeleft: '25px',
            Afterleft: '20px'
        }
    ];

    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="">
                        <div className="paddingRight0"></div>
                        <div className="">
                            <div className="">
                                <div className="seller-payment-inner bg-change remindRewardProgram-main">
                                    <section className="container-graph ">
                                        <div className="notes">
                                            <h3 className="">Remind Rewards program</h3>
                                            <p className="main-content-para ">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
                                        </div>
                                        <div className="remindRewardProgram-buttons-graph mb-5">
                                            <div className={`giftbtn ${activeButton === 'giftbtn' ? 'remindRewardProgramActive-graph' : ''}`} onClick={() => handleClick('giftbtn')}>
                                                Gifting Program
                                            </div>

                                            {/* Referral Program button */}
                                            <div className={`referalbtn ${activeButton === 'referalbtn' ? 'remindRewardProgramActive-graph' : ''}`} onClick={() => handleClick('referalbtn')}>
                                                Referral Program
                                            </div>
                                        </div>

                                        <div className="lg-container desktop-only">
                                            <img className="lg-image" src={prograssCruve} alt="Progress Carve" />

                                            {hotspots.map((hotspot, index) => (
                                                <div key={index} className={`lg-hotspot ${hotspot.position} ${selectedHotspot === index ? 'lg-hotspot--selected' : ''}`}>
                                                    <div className={`all-inner-div ${hotspot.className || ''}`}>
                                                        <img className={hotspot.className || 'img-p1'} src={hotspot.src} alt="Hotspot" />
                                                        {hotspot.tooltipType && <div className={`tooltip ${hotspot.tooltipClass || ''}`}>{hotspot.tooltip}</div>}

                                                        {hotspot.before && (
                                                            <span className="before-text" style={{ position: 'absolute', top: hotspot.beforetop, left: hotspot.Beforeleft }}>
                                                                {hotspot.before}
                                                            </span>
                                                        )}
                                                        {hotspot.after && (
                                                            <span className="after-text" style={{ position: 'absolute', top: hotspot.aftertop, left: hotspot.Afterleft }}>
                                                                {hotspot.after}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div className="lg-container mobile-only">
                                            <img className="lg-image" src={progressCarveMobile} alt="Progress Carve" />

                                            {MobileHotspots.map((hotspot, index) => (
                                                <div key={index} className={`lg-hotspot ${hotspot.position} ${selectedHotspot === index ? 'lg-hotspot--selected' : ''}`}>
                                                    <div className={`all-inner-div ${hotspot.className || ''}`}>
                                                        <img className={hotspot.className || 'img-p1'} src={hotspot.src} alt="Hotspot" />
                                                        {hotspot.tooltipType && <div className={`tooltip ${hotspot.tooltipClass || ''}`}>{hotspot.tooltip}</div>}

                                                        {hotspot.before && (
                                                            <span className="before-text" style={{ position: 'absolute', top: hotspot.beforetop, left: hotspot.Beforeleft }}>
                                                                {hotspot.before}
                                                            </span>
                                                        )}
                                                        {hotspot.after && (
                                                            <span className="after-text" style={{ position: 'absolute', top: hotspot.aftertop, left: hotspot.Afterleft }}>
                                                                {hotspot.after}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div className="nailing-it">
                                            <div className="inner-nailin-it">
                                                <h3 className="nailing-heading">
                                                    <span style={{ fontWeight: 'bold' }}>You’re nailing it Zeeshan,</span> Keep going!
                                                </h3>
                                                <div className="inner-subs-gift-section">
                                                    <div className="subs-gift">
                                                        <h3 className="inner-headings-nailing-it">37</h3>
                                                        <p className="para-inner-nailing-it">Subscriptions Gifted</p>
                                                    </div>
                                                    <div className="total-earning">
                                                        <h3 className="inner-headings-nailing-it">$50</h3>
                                                        <p className="para-inner-nailing-it">Total Earnings</p>
                                                    </div>
                                                </div>
                                                <div className="btn-nailing-wrap">
                                                    <Link to="/seller/remind-seller-program">Learn Details</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default REmindSellerProgramGraph;
