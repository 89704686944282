import { legacy_createStore as createStore, applyMiddleware, combineReducers } from 'redux';
import { rootReducer } from './reducer/rootReducer';
import { thunk } from 'redux-thunk';
const reducers = { root: rootReducer };
export const store = () => {
    const middleWare = [thunk];
    return createStore(
        combineReducers({
            ...reducers
        }),
        applyMiddleware(...middleWare)
    );
};
