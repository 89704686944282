import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './loader.css';

const Loader = ({ size }) => {
    return (
        <div className="d-flex justify-content-center align-items-center centered-with-margin">
            <Spinner className="loader" size={size || 'md'} animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
};

export default Loader;
