import './OffCanvasBootstrap.css';
import { Offcanvas } from 'react-bootstrap';

const OffCanvasBootstrap = ({ closeButton, show, title, children, handleClose, ...props }) => {
    return (
        <Offcanvas className="custom-offcanvas" show={show} onHide={handleClose} {...props}>
            <Offcanvas.Header closeButton={closeButton || false}>
                <Offcanvas.Title>{title || ''}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>{children}</Offcanvas.Body>
        </Offcanvas>
    );
};

export default OffCanvasBootstrap;
