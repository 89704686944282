import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { plansList } from '../../../axiosCalls/auth';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { createPaymentSubIntent } from '../../../axiosCalls/seller';
import { toast } from 'react-toastify';
import { create_subscription, getProperties } from '../../../axiosCalls/userdashboard';
export default function UpdgradePlan({ shown, onClose }) {
    const [plans, setPlans] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [filteredPlans, setFilteredPlans] = useState([]);
    const [allPlans, setAllPlans] = useState([]);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [propertyList, setPropertyList] = useState([]);
    const [loading, setLoading] = useState(true); // Track loading state
    const [error, setError] = useState(null); // Track errors
    const stripe = useStripe();
    const storedUser = localStorage.getItem('user');

    const [user, setUser] = useState(JSON.parse(storedUser));
    const elements = useElements();
    useEffect(() => {
        fetchPlans();
    }, [shown]);
    const fetchPlans = async () => {
        try {
            const response = await plansList();
            setPlans(response.data);
            const nonReferralPlans = response.data.filter((plan) => plan.referral === 'no');
            setAllPlans(response.data);
            setFilteredPlans(nonReferralPlans);
        } catch (error) {
            console.error('Error fetching plans:', error);
            setError('Failed to load plans. Please try again later.');
        } finally {
            setLoading(false); // Stop loading after fetch
        }
    };
    const handlePlanChange = (event) => {
        const selectedId = Number(event.target.value);
        const selected = plans.find((plan) => plan.id === selectedId);
        setSelectedPlan(selected || null);
    };
    const handleClose = () => {
        onClose();
        setFilteredPlans([]);
        setSelectedPlan(null);
    };
    const upgradePlanFuncIntent = async () => {
        if (!stripe || !elements) {
            return;
        }

        const userEmail = user.email;
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: { email: userEmail }
        });

        const body = JSON.stringify({
            payment_method: paymentMethod.id,
            email: userEmail,
            price_id: selectedPlan.stripe_plan_id
        });
        const res = await create_subscription(body);

        if (error) {
            setPaymentLoading(false);
        } else {
            setPaymentLoading(false);
            toast.success('Payment successful!');
        }
    };

    const handlerFetchProperty = async () => {
        try {
            const response = await getProperties();
            if (response && response.data) {
                setPropertyList(response.data);
            }
        } catch (error) {
            console.error('Error fetching properties:', error);
        }
    };
    useEffect(() => {
        handlerFetchProperty();
    }, []);
    useMemo(() => {
        const isRefferalUser = user?.meta_data?.referral_code;
        const isGiftSubUser = user?.meta_data?.gift_subscription;
        const propertyLength = propertyList.length;
        if (!isRefferalUser || !isGiftSubUser || !(isGiftSubUser === 'yes')) {
            const activePlan = filteredPlans.filter((ii) => Number(ii?.properties_allowed) > propertyLength - 1 && Number(ii?.properties_allowed) <= propertyLength);
            setFilteredPlans(activePlan);
            setSelectedPlan(activePlan[0] || null);
        }
        if (isRefferalUser || isGiftSubUser || isGiftSubUser === 'yes') {
            const nonReferralPlans = allPlans.filter((plan) => plan.referral === 'yes');
            const activePlan = nonReferralPlans.filter((ii) => Number(ii?.properties_allowed) > propertyLength - 1 && Number(ii?.properties_allowed) <= propertyLength);
            setFilteredPlans(activePlan);
            setSelectedPlan(activePlan[0] || null);
        }
    }, [propertyList.length, filteredPlans.length]);
    if (loading) return <p>Loading plans...</p>;
    if (error) return <p>{error}</p>;
    return (
        <Modal show={shown} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered size="md">
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label htmlFor="selectPlan">Select Plan</label>
                    <select className="form-control form-control-md" id="selectPlan" name="planId" value={selectedPlan?.title || ''} onChange={handlePlanChange} required>
                        <option value="">Select a plan</option>
                        {filteredPlans.map((plan) => (
                            <option key={plan.id}>{plan.title}</option>
                        ))}
                    </select>

                    {selectedPlan && (
                        <div className="form-group mt-3">
                            <label>Selected Plan Details:</label>
                            <table className="plan-table table">
                                <tbody>
                                    <tr>
                                        <th scope="row">Title</th>
                                        <td>{selectedPlan.title}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Properties Allowed</th>
                                        <td>{selectedPlan.properties_allowed}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Price</th>
                                        <td>${selectedPlan.price}/year</td>
                                    </tr>
                                </tbody>
                            </table>
                            <CardElement
                                options={{
                                    style: {
                                        base: {
                                            marginTop: '10px',
                                            fontSize: '16px',
                                            color: 'black',
                                            '::placeholder': {
                                                color: '#808080'
                                            }
                                        },
                                        invalid: {
                                            color: '#9e2146'
                                        }
                                    }
                                }}
                            />
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="logout-row">
                    <div className="logout-button" onClick={handleClose}>
                        Cancel
                    </div>
                </div>
                <div className="reminder-top-button">
                    <button className="filled-btn" disabled={paymentLoading} onClick={upgradePlanFuncIntent}>
                        {paymentLoading ? <Spinner size="sm" /> : 'Upgrade Plan'}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
