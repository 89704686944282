import * as types from '../actions/actionTypes';

export const initialMMState = {
    dummy: '',
};

export const rootReducer = (state = initialMMState, action) => {
    switch (action.type) {
        case types.ALL_RECORDS:
            return { ...state, [action.data.keyOfData]: action.data.data };
        default:
            return state;
    }
};
