import React, { useState } from 'react';
import SellerSiderbar from '../sidebar/sellerSiderbar';
import '../assets/sellerDashboard.css';
import SellerHeader from '../components/header/sellerHeader';
import Footer from '../components/footer/footer';
import { Link, Navigate } from 'react-router-dom';
import swtichIcon from '../assets/icons/refresh-cw.svg';

const SellerDashboard = (props) => {
    const { childComp } = props;

    const [openSideBar, setOpenSideBar] = useState('close');

    const handlerSideBar = (val) => {
        setOpenSideBar(val);
    };
    const handlercolseSidebar = () => {
        setOpenSideBar('close');
        document.body.classList.remove('no-scroll');
    };
    const handlerCloseSidebarFromClick = (val) => {
        setOpenSideBar(val);
    };
    const token = localStorage.getItem('token');
    if (!token) {
        // Not authenticated
        return <Navigate to="/login" />;
    }
    return (
        <div className="container-scroller seller">
            <SellerHeader stateSide={openSideBar} handlerSideBar={handlerSideBar} />
            <div className="page-body-wrapper">
                <SellerSiderbar stateSide={openSideBar} handlerCloseSidebarFromClick={handlerCloseSidebarFromClick} />
                <div className="main-panel">
                    <div className="reminder-top-button homeOwnerRole">
                        <Link to="/dashboard" className="seller-switch-role">
                            <img src={swtichIcon} alt="switch-icon" /> Switch to <b>Homeowner Role</b>
                        </Link>
                    </div>
                    {childComp}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SellerDashboard;
