import React, { useState, useEffect } from 'react';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Form } from 'react-bootstrap';
import { Select } from './Select';
import { listOfFrequencies, fetchHomeEssentials, addCustomReminder, addNotificationData } from '../../../axiosCalls/userdashboard';
import { toast } from 'react-toastify';

const ReminderSideNavCreate = ({ onCancel, onBack }) => {
    const [frequencies, setFrequencies] = useState([]);
    const [homeEssentials, setHomeEssentials] = useState([]);

    useEffect(() => {
        const fetchFrequencies = async () => {
            try {
                const response = await listOfFrequencies();
                setFrequencies(response.data);
            } catch (error) {
                console.error('Error fetching frequencies:', error);
            }
        };

        const fetchEssentials = async () => {
            try {
                const response = await fetchHomeEssentials();
                const optionsData = response.data.items.map((item) => ({
                    label: `${item.type} - ${item.model_number}`,
                    value: item.id
                }));
                setHomeEssentials(optionsData);
            } catch (error) {
                console.error('Error fetching home essentials:', error);
            }
        };

        fetchFrequencies();
        fetchEssentials();
    }, []);

    const initialValues = {
        title: '',
        description: '',
        frequency: '',
        date: new Date().toISOString().split('T')[0],
        time: new Date().toTimeString().slice(0, 5),
        linkedHomeEssentials: []
    };

    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required'),
        frequency: Yup.string().required('Frequency is required'),
        date: Yup.string().required('Date is required')
        // time: Yup.string().required('Time is required')
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            const reminderData = {
                title: values.title,
                description: values.description,
                frequency: values.frequency,
                date: values.date,
                time: values.time,
                linkedHomeEssentials: values.linkedHomeEssentials
            };
            const responseCustomReminder = await addCustomReminder(reminderData);
            const response = await addNotificationData(reminderData, responseCustomReminder.data.post_id);
            if (response && response.data) {
                if (response.data.status === 400) {
                    toast.error(response.message);
                } else {
                    toast.success(response.data.message);
                }
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div style={{ width: '100%' }}>
            <div className="side-header">
                <h3>Add Reminder</h3>
            </div>
            <div className="side-body CreateReminderSideBody">
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ isSubmitting, errors, setFieldValue, handleChange, touched, values }) => (
                        <FormikForm>
                            <Form.Group controlId="formTitle">
                                <Form.Label>
                                    Title
                                    <span className="requiredStar">*</span>
                                </Form.Label>
                                <Form.Control type="text" placeholder="Name" name="title" value={values.title} onChange={handleChange} isInvalid={touched.title && errors.title} />
                                <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formDescription">
                                <Form.Label>Description</Form.Label>
                                <Field className="form-control" name="description" as="textarea" rows={3} placeholder="Enter Description" />
                            </Form.Group>

                            <Form.Group controlId="formFrequency">
                                <Form.Label>
                                    Frequency<span className="requiredStar">*</span>
                                </Form.Label>

                                <Form.Select name="frequency" value={values.frequency} onChange={handleChange} isInvalid={touched.frequency && errors.frequency}>
                                    <option value="">Select Frequency</option>
                                    {frequencies.map((type) => (
                                        <option key={type.id} value={type.name}>
                                            {type.name}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formDate">
                                <Form.Label>
                                    Reminder Start Date <span className="requiredStar">*</span>
                                </Form.Label>
                                <Form.Control type="date" name="date" value={values.date} onChange={handleChange} isInvalid={touched.date && errors.date} />
                                <Form.Control.Feedback type="invalid">{errors.date}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="formTime">
                                <Form.Label>Time</Form.Label>
                                <Field className="form-control" name="time" type="time" />
                                <ErrorMessage name="time" component="div" className="error" />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Linked Home Essentials</Form.Label>
                                <Select multiple options={homeEssentials} value={values.linkedHomeEssentials} onChange={(selected) => setFieldValue('linkedHomeEssentials', selected)} />
                            </Form.Group>

                            <div className="side-footer form-actions d-flex justify-content-between gap-2">
                                <Button variant="secondary" onClick={onBack}>
                                    Back
                                </Button>
                                <Button className="button-cancel" onClick={onCancel} disabled={isSubmitting}>
                                    Cancel
                                </Button>
                                <Button className="button-primary" type="submit" disabled={isSubmitting}>
                                    Save
                                </Button>
                            </div>
                        </FormikForm>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ReminderSideNavCreate;
