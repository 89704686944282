import React from 'react';
import DataTable, { Alignment, Direction } from 'react-data-table-component';

const Projects = () => {
    const columnsExpens = [
        {
            name: 'Category',
            selector: (row) => row.Category,
            sortable: true,
            reorder: true
        },
        {
            name: 'Name',
            selector: (row) => row.Name,
            sortable: true,
            reorder: true
        },
        {
            name: '',
            selector: (row) => row.extra1,
            sortable: true,
            reorder: true
        },
        {
            name: '',
            selector: (row) => row.extra2,
            sortable: true,
            reorder: true
        },

        {
            name: 'Property Name',
            selector: (row) => row.PropertyName,
            sortable: true,
            reorder: true
        },
        {
            name: 'Date',
            selector: (row) => row.Date,
            sortable: true,
            reorder: true
        },
        {
            name: 'Amount',
            selector: (row) => row.Amount,
            sortable: true,
            reorder: true
        }
    ];

    const dataExpens = [
        {
            id: 1,
            Category: <span className="services-badge type-green">Home Essential</span>,
            Name: 'Thoreau Reflector',
            extra1: 'Morbi et',
            extra2: 'Pulvinar ac',
            PropertyName: 'Jordane Enterprises',
            Date: '04/01/2023',
            Amount: '$4,985'
        },
        {
            id: 2,
            Category: <span className="services-badge type-camel">Service</span>,
            Name: 'Thoreau Reflector',
            extra1: 'Morbi et',
            extra2: 'Pulvinar ac',
            PropertyName: 'Jordane Enterprises',
            Date: '04/01/2023',
            Amount: '$4,985'
        },
        {
            id: 3,
            Category: <span className="services-badge type-blue">Other</span>,
            Name: 'Thoreau Reflector',
            extra1: 'Morbi et',
            extra2: 'Pulvinar ac',
            PropertyName: 'Jordane Enterprises',
            Date: '04/01/2023',
            Amount: '$4,985'
        },
        {
            id: 1,
            Category: <span className="services-badge type-green">Home Essential</span>,
            Name: 'Thoreau Reflector',
            extra1: 'Morbi et',
            extra2: 'Pulvinar ac',
            PropertyName: 'Jordane Enterprises',
            Date: '04/01/2023',
            Amount: '$4,985'
        }
    ];
    return (
        <div className="col-md-12 mb-3" id="project-cost">
            <label className="reportSectiontitile">Project: Name</label>
            <div className="recentPurcheseTable projectTableReports">
                <DataTable
                    columns={columnsExpens}
                    data={dataExpens}
                    defaultSortFieldId={2}
                    selectableRows={false}
                    selectableRowsNoSelectAll={false}
                    selectableRowsVisibleOnly={false}
                    selectableRowsHighlight={false}
                    selectableRowsSingle={false}
                    expandableRows={false}
                    expandOnRowClicked={false}
                    expandOnRowDoubleClicked={false}
                    expandableRowsHideExpander={false}
                    pagination={false}
                    highlightOnHover={true}
                    striped={false}
                    pointerOnHover={false}
                    dense={false}
                    persistTableHead={true}
                    noHeader={false}
                    fixedHeader={false}
                    fixedHeaderScrollHeight={'300px'}
                    progressPending={false}
                    noTableHead={false}
                    noContextMenu={false}
                    direction={Direction.AUTO}
                    subHeader={false}
                    subHeaderAlign={Alignment.RIGHT}
                    subHeaderWrap={true}
                    responsive={true}
                    disabled={false}
                    className="proteryReport"
                />
                <div className="project-table-footer-cost">Project cost: $430.40</div>
            </div>
        </div>
    );
};

export default Projects;
