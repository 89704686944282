import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import MyNetworkTable from '../inc/myNetworkTable';
import MagnifyingGlass from '../../user-dashboard/asstes/dash-icons/sidebar/MagnifyingGlass.svg';
import { getDataMyNetwork } from '../../axiosCalls/seller';

const MyNetwork = () => {
    const [networkData, setNetworkData] = useState([]); // Flattened data
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortOption, setSortOption] = useState(''); // Default sorting option

    // Fetch and format data on component mount
    useEffect(() => {
        const fetchNetworkData = async () => {
            try {
                const res = await getDataMyNetwork();
                if (res.status === 200) {
                    const { gift_reminds, refer_reminds } = res.data;

                    // Combine the two arrays into one
                    const combinedData = [
                        ...gift_reminds.map((item) => ({
                            ...item, // Spread existing properties
                            type: 'gift' // Add a type for identification
                        })),
                        ...refer_reminds.map((item) => ({
                            ...item,
                            type: 'refer'
                        }))
                    ];

                    setNetworkData(combinedData); // Set combined data
                    setFilteredData(combinedData); // Initialize filtered data
                }
            } catch (error) {
                console.error('Failed to fetch network data:', error);
            }
        };

        fetchNetworkData();
    }, []);

    // Handle search input change
    const handleSearch = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);

        const filtered = networkData.filter((item) => item.homeowner_first_name?.toLowerCase().includes(value) || item.homeowner_last_name?.toLowerCase().includes(value));
        setFilteredData(filtered);
    };

    // Handle sorting
    const handleSort = (e) => {
        const value = e.target.value;
        setSortOption(value);

        const sortedData = [...filteredData];
        if (value === 'Alphabet') {
            sortedData.sort((a, b) => a.homeowner_first_name.localeCompare(b.homeowner_first_name));
        } else if (value === 'Date') {
            sortedData.sort((a, b) => new Date(a.signup_date || 0) - new Date(b.signup_date || 0));
        }
        setFilteredData(sortedData);
    };

    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-5 col-md-12 col-sm-12">
                                    <h5 className="mb-2 text-titlecase mb-3">My Network</h5>
                                </div>
                            </div>

                            <div className="">
                                <div className="row mb-1">
                                    <div className="col-md-9 seller-my-network-sorting">
                                        <InputGroup className="search-field mb-1 mt-0">
                                            <InputGroup.Text id="basic-addon1">
                                                <img src={MagnifyingGlass} alt="Search Icon" />
                                            </InputGroup.Text>
                                            <Form.Control type="text" placeholder="Search" value={searchTerm} onChange={handleSearch} />
                                        </InputGroup>
                                    </div>

                                    <div className="col-md-3 card-right-action mynetworkSorting mb-2">
                                        <div className="recent-earning-sortlble">
                                            <label>Sort by</label>
                                            <Form.Select value={sortOption} onChange={handleSort}>
                                                <option value="Alphabet">Alphabet</option>
                                                <option value="Date">Date</option>
                                            </Form.Select>
                                        </div>
                                    </div>
                                </div>

                                <MyNetworkTable data={filteredData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyNetwork;
