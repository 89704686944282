import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const isPropertyExist = () => {
    const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
    if (!propertyData) {
        toast.error('Please Add Property First');
        return false;
    }
    return true;
};
const isPlanActive = () => {
    const userDetail = JSON.parse(localStorage.getItem('user'));
    if (userDetail?.is_plan_active) {
        return true;
    }
    return false;
};
const confirmDelete = (callback) => {
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
        callback(result);
    });
};
export { isPropertyExist, isPlanActive, confirmDelete };
