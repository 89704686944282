import React from 'react';
import DataTable, { Alignment, Direction } from 'react-data-table-component';

const Tasks = () => {
    const columnsTaskOverview = [
        {
            name: 'Home Essentials',
            selector: (row) => row.name,
            sortable: true,
            reorder: true
        },
        {
            name: '',
            selector: (row) => row.empty,
            sortable: true,
            reorder: true
        },
        {
            name: 'Completed Tasks',
            selector: (row) => row.completed,
            sortable: true,
            reorder: true
        }
    ];
    const dataTaskOverview = [
        {
            id: 1,
            name: 'Refrigerator',
            completed: 20
        },
        {
            id: 1,
            name: 'Air Conditioner',
            completed: 10
        },
        {
            id: 1,
            name: 'Lights',
            completed: 20
        },
        {
            id: 1,
            name: 'Other',
            completed: 50
        }
    ];
    const columnsTask = [
        {
            name: 'Task Name',
            selector: (row) => row.name,
            sortable: true,
            reorder: true
        },
        {
            name: 'Date',
            selector: (row) => row.date,
            sortable: true,
            reorder: true
        },
        {
            name: 'Status',
            selector: (row) => row.status,
            sortable: true,
            reorder: true
        }
    ];
    const dataTask = [
        {
            id: 1,
            status: <span className="services-badge type-green">Completed</span>,
            name: 'Refrigerator',
            date: '04/01/2023',
            cost: '$4,985'
        },

        {
            id: 1,
            status: <span className="services-badge type-green">Completed</span>,
            name: 'Refrigerator',
            date: '04/01/2023',
            cost: '$4,985'
        },

        {
            id: 1,
            status: <span className="services-badge type-green">Completed</span>,
            name: 'Refrigerator',
            date: '04/01/2023',
            cost: '$4,985'
        },

        {
            id: 1,
            status: <span className="services-badge type-green">Completed</span>,
            name: 'Refrigerator',
            date: '04/01/2023',
            cost: '$4,985'
        }
    ];
    return (
        <div className="row pe-0 me-0" id="task-detail-overview">
            <div className=" col-md-6 mb-3">
                <label className="reportSectiontitile">Task Overview</label>
                <div className="recentPurcheseTable" id="taskTable">
                    <DataTable
                        columns={columnsTaskOverview}
                        data={dataTaskOverview}
                        defaultSortFieldId={2}
                        selectableRows={false}
                        selectableRowsNoSelectAll={false}
                        selectableRowsVisibleOnly={false}
                        selectableRowsHighlight={false}
                        selectableRowsSingle={false}
                        expandableRows={false}
                        expandOnRowClicked={false}
                        expandOnRowDoubleClicked={false}
                        expandableRowsHideExpander={false}
                        pagination={false}
                        highlightOnHover={true}
                        striped={false}
                        pointerOnHover={false}
                        dense={false}
                        persistTableHead={true}
                        noHeader={false}
                        fixedHeader={false}
                        fixedHeaderScrollHeight={'300px'}
                        progressPending={false}
                        noTableHead={false}
                        noContextMenu={false}
                        direction={Direction.AUTO}
                        subHeader={false}
                        subHeaderAlign={Alignment.RIGHT}
                        subHeaderWrap={true}
                        responsive={true}
                        disabled={false}
                        className="proteryReport"
                    />
                </div>
            </div>

            <div className="col-md-6 mb-3">
                <label className="reportSectiontitile">Task Details</label>
                <div className="recentPurcheseTable" id="taskTable">
                    <DataTable
                        columns={columnsTask}
                        data={dataTask}
                        defaultSortFieldId={2}
                        selectableRows={false}
                        selectableRowsNoSelectAll={false}
                        selectableRowsVisibleOnly={false}
                        selectableRowsHighlight={false}
                        selectableRowsSingle={false}
                        expandableRows={false}
                        expandOnRowClicked={false}
                        expandOnRowDoubleClicked={false}
                        expandableRowsHideExpander={false}
                        pagination={false}
                        highlightOnHover={true}
                        striped={false}
                        pointerOnHover={false}
                        dense={false}
                        persistTableHead={true}
                        noHeader={false}
                        fixedHeader={false}
                        fixedHeaderScrollHeight={'300px'}
                        progressPending={false}
                        noTableHead={false}
                        noContextMenu={false}
                        direction={Direction.AUTO}
                        subHeader={false}
                        subHeaderAlign={Alignment.RIGHT}
                        subHeaderWrap={true}
                        responsive={true}
                        disabled={false}
                        className="proteryReport"
                    />
                </div>
            </div>
        </div>
    );
};

export default Tasks;
