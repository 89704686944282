import React, { useState, useEffect } from 'react';
import eyeIcon from '../../user-dashboard/asstes/dash-icons/eyeIcon.svg';
import copyIcon from '../assets/icons/copyIcon.svg';
import deActiveIcon from '../assets/icons/deActiveIcon.svg';
import deleteIcon from '../assets/icons/deleteIcon.svg';

import ActiveIcon from '../assets/icons/ActiveIcon.svg';
import DataTable, { Alignment, Direction } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { updateReralCode } from '../../axiosCalls/seller';
import { Spinner } from 'react-bootstrap';
import './style.css';

const ShowCodeHistory = ({ referData, loading }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(
            referData?.map((item) => ({
                ...item,
                isLoading: false, // Add loading state for each item
                ClientName: <div className="fileName">{item.referral_code}</div>,
                ReferralCode: <div className={`fileName ReferralStatus type-${item.status === 'active' ? 'green' : 'blue'}`}>{item.status.charAt(0).toUpperCase() + item.status.slice(1)}</div>
            }))
        );
    }, [referData]);

    const handleCopy = (code) => {
        navigator.clipboard
            .writeText(code)
            .then(() => {
                toast.success('Referral code copied to clipboard!');
            })
            .catch((error) => {
                console.error('Failed to copy referral code: ', error);
            });
    };

    const handlerStatusUpdate = async (id) => {
        setData((prevData) =>
            prevData.map((item) =>
                item.id === id
                    ? {
                          ...item,
                          isLoading: true // Show loader for the toggled item
                      }
                    : item
            )
        );

        const itemToUpdate = data.find((item) => item.id === id);
        const newStatus = itemToUpdate.status === 'active' ? 'inactive' : 'active';

        try {
            const res = await updateReralCode(id, { status: newStatus });

            if (res.status === 200) {
                toast.success(res.data.message);

                setData((prevData) =>
                    prevData.map((item) =>
                        item.id === id
                            ? {
                                  ...item,
                                  status: newStatus,
                                  isLoading: false, // Hide loader after update
                                  ReferralCode: <div className={`fileName ReferralStatus type-${newStatus === 'active' ? 'green' : 'blue'}`}>{newStatus.charAt(0).toUpperCase() + newStatus.slice(1)}</div>
                              }
                            : item
                    )
                );
            }
        } catch (error) {
            console.error('Error updating status:', error);
            toast.error('Failed to update status. Please try again.');

            // Reset loader state in case of failure
            setData((prevData) =>
                prevData.map((item) =>
                    item.id === id
                        ? {
                              ...item,
                              isLoading: false
                          }
                        : item
                )
            );
        }
    };

    const handleDelete = async () => {};

    const columns = [
        {
            name: 'Referral Code',
            selector: (row) => row.ClientName,
            sortable: false,
            reorder: true
        },
        {
            name: 'Status',
            selector: (row) => row.ReferralCode,
            sortable: false,
            reorder: true
        },
        {
            name: 'Date Created',
            selector: (row) => <div style={{ paddingLeft: '20px' }}>{new Date(row.created_date).toLocaleDateString()}</div>,
            sortable: false,
            reorder: true
        },
        {
            name: 'Action',
            cell: (row) => (
                <div className="actions-data">
                    {row.isLoading ? (
                        <div>
                            <Spinner className="small-spinner" />
                        </div>
                    ) : (
                        <span onClick={() => handlerStatusUpdate(row.id)} style={{ cursor: 'pointer' }}>
                            <img src={row.status === 'active' ? ActiveIcon : deActiveIcon} alt="status icon" />
                        </span>
                    )}
                    <span onClick={() => handleCopy(row.referral_code)} style={{ cursor: 'pointer' }}>
                        <img src={copyIcon} alt="copy icon" />
                    </span>
                    <span onClick={() => handleDelete(row.referral_code)} style={{ cursor: 'pointer' }}>
                        <img src={deleteIcon} alt="copy icon" />
                    </span>
                </div>
            ),
            sortable: false,
            reorder: true
        }
    ];

    return (
        <div className="recentFilesTable recentEarning">
            <DataTable
                columns={columns}
                data={data}
                defaultSortFieldId={2}
                selectableRows={false}
                selectableRowsNoSelectAll={false}
                selectableRowsVisibleOnly={false}
                selectableRowsHighlight={false}
                selectableRowsSingle={false}
                expandableRows={false}
                expandOnRowClicked={false}
                expandOnRowDoubleClicked={false}
                expandableRowsHideExpander={false}
                pagination={true}
                highlightOnHover={true}
                striped={false}
                pointerOnHover={false}
                dense={false}
                persistTableHead={true}
                noHeader={false}
                fixedHeader={false}
                fixedHeaderScrollHeight={'300px'}
                progressPending={loading}
                noTableHead={false}
                noContextMenu={false}
                direction={Direction.AUTO}
                subHeader={false}
                subHeaderAlign={Alignment.RIGHT}
                subHeaderWrap={true}
                responsive={true}
                disabled={false}
            />
        </div>
    );
};

export default ShowCodeHistory;
