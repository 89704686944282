import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';

export default function AddLineItem({ show, onClose, setFieldValue, formValues, selectedIndex }) {
    const [initialState, setInitialState] = useState({
        productName: '',
        make: '',
        modelNumber: '',
        productPrice: ''
    });

    useEffect(() => {
        if (selectedIndex || selectedIndex === 0) {
            setInitialState({
                productName: formValues?.lineItems[selectedIndex]?.name,
                make: formValues?.lineItems[selectedIndex]?.make || '',
                modelNumber: formValues?.lineItems[selectedIndex]?.modelNumber || '',
                productPrice: formValues?.lineItems[selectedIndex]?.price
            });
        } else {
            setInitialState({
                productName: '',
                make: '',
                modelNumber: '',
                productPrice: ''
            });
        }
    }, [formValues?.lineItems, selectedIndex]);

    const handleSubmit = (values, { resetForm }) => {
        if (!selectedIndex && selectedIndex !== 0) {
            setFieldValue('lineItems', [
                ...(formValues?.lineItems || []),
                {
                    name: values.productName,
                    make: values.make || '',
                    modelNumber: values.modelNumber || '',
                    price: values.productPrice
                }
            ]);
        } else {
            let lineItems = [...formValues?.lineItems];
            lineItems[selectedIndex] = {
                name: values.productName,
                make: values.make,
                modelNumber: values.modelNumber,
                price: values.productPrice
            };
            setFieldValue('lineItems', lineItems);
        }
        resetForm();
        handleClose();
    };

    const handleClose = () => {
        setInitialState({
            productName: '',
            make: '',
            modelNumber: '',
            productPrice: ''
        });
        onClose();
    };

    const validationSchema = Yup.object().shape({
        productName: Yup.string().required('Item Name is required'),
        // make: Yup.string().required('Make is required'),
        // modelNumber: Yup.string().required('Model Number is required'),
        productPrice: Yup.number().typeError('Item Price must be a number').required('Item Price is required')
    });

    return (
        <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered size="md">
            <Modal.Header closeButton />
            <Formik enableReinitialize initialValues={initialState} validationSchema={validationSchema} onSubmit={handleSubmit}>
                {({ errors, values, touched, handleChange }) => (
                    <FormikForm>
                        <Modal.Body>
                            <Form.Group as={Col} sm="12" className="mb-3">
                                <Form.Label>
                                    Item Name <span className="requiredStar">*</span>
                                </Form.Label>
                                <Form.Control name="productName" type="text" placeholder="Please Enter Item Name" value={values.productName} onChange={handleChange} isInvalid={touched.productName && !!errors.productName} />
                                <Form.Control.Feedback type="invalid">{errors.productName}</Form.Control.Feedback>
                            </Form.Group>
                            {/* // add fields for make and modelNumber */}
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Make
                                    {/* <span className="requiredStar">*</span> */}
                                </Form.Label>
                                <Form.Control name="make" type="text" placeholder="Please Enter Make" value={values.make} onChange={handleChange} isInvalid={touched.make && !!errors.make} />
                                {/* <Form.Control.Feedback type="invalid">{errors.make}</Form.Control.Feedback> */}
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Model Number
                                    {/* <span className="requiredStar">*</span> */}
                                </Form.Label>
                                <Form.Control name="modelNumber" type="text" placeholder="Please Enter Model Number" value={values.modelNumber} onChange={handleChange} isInvalid={touched.modelNumber && !!errors.modelNumber} />
                                {/* <Form.Control.Feedback type="invalid">{errors.modelNumber}</Form.Control.Feedback> */}
                            </Form.Group>

                            <Form.Group as={Col} sm="12" className="mb-3">
                                <Form.Label>
                                    Item Price <span className="requiredStar">*</span>
                                </Form.Label>
                                <Form.Control name="productPrice" type="number" placeholder="Please Enter Item Price" value={values.productPrice} onChange={handleChange} isInvalid={touched.productPrice && !!errors.productPrice} />
                                <Form.Control.Feedback type="invalid">{errors.productPrice}</Form.Control.Feedback>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="logout-row">
                                <div className="logout-button" onClick={handleClose}>
                                    Cancel
                                </div>
                            </div>
                            <div className="reminder-top-button">
                                <Button className="filled-btn" type="submit">
                                    {selectedIndex || selectedIndex === 0 ? 'Update' : 'Add'}
                                </Button>
                            </div>
                        </Modal.Footer>
                    </FormikForm>
                )}
            </Formik>
        </Modal>
    );
}
