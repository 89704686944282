import { useEffect } from 'react';
import { useFormikContext } from 'formik';

export default function ScrollToError() {
    const { errors, isSubmitting } = useFormikContext();

    useEffect(() => {
        if (isSubmitting) {
            const errorFields = Object.keys(errors);
            if (errorFields.length > 0) {
                const firstErrorField = document.getElementsByName(errorFields[0])[0];
                if (firstErrorField) {
                    const accordionItem = firstErrorField.closest('.accordion-item');
                    if (accordionItem) {
                        const accordionHeader = accordionItem.querySelector('.accordion-button');
                        if (accordionHeader && accordionHeader.getAttribute('aria-expanded') === 'false') {
                            accordionHeader.click();
                        }
                    }
                    firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    firstErrorField.focus();
                }
            }
        }
    }, [isSubmitting, errors]);

    return null;
}
