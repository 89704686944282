import React from 'react';

const Services = () => {
    return (
        <div className="col-md-12 mb-3" id="project-cost">
            <label className="reportSectiontitile">Services</label>
            <div className="recentPurcheseTable projectTableReports">Services</div>
        </div>
    );
};

export default Services;
