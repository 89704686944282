import React, { useState } from 'react';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { contactUs } from '../../../axiosCalls/userdashboard';
import { toast } from 'react-toastify';
import './style.css';

const ContactModal = ({ showModal, handleClose }) => {
    const [isLoading, setIsLoading] = useState(false); // State to track loading status

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();

    const onSubmit = async (data) => {
        // Map form fields to API fields
        const formData = {
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            message: data.message
        };

        try {
            setIsLoading(true);
            const response = await contactUs(formData);
            if (response?.data?.status === 200) {
                reset();
                toast.success(response?.data?.message || 'Submitted successfully');
                handleClose();
            }
        } catch (error) {
            console.error('Error submitting contact form:', error);
            toast.error('Failed to submit form. Please try again');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header>
                <Modal.Title className="text-center w-100">Contact Us</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {/* First Name */}
                    <Form.Group controlId="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter First Name" {...register('firstName', { required: 'First name is required' })} />
                        {errors.firstName && <p className="text-danger">{errors.firstName.message}</p>}
                    </Form.Group>

                    {/* Last Name */}
                    <Form.Group controlId="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter Last Name" {...register('lastName', { required: 'Last name is required' })} />
                        {errors.lastName && <p className="text-danger">{errors.lastName.message}</p>}
                    </Form.Group>

                    {/* Email */}
                    <Form.Group controlId="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter Email"
                            {...register('email', {
                                required: 'Email is required',
                                pattern: {
                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                    message: 'Invalid email address'
                                }
                            })}
                        />
                        {errors.email && <p className="text-danger">{errors.email.message}</p>}
                    </Form.Group>

                    {/* Message */}
                    <Form.Group controlId="message">
                        <Form.Label>Message</Form.Label>
                        <Form.Control as="textarea" rows={3} placeholder="Enter your message" {...register('message', { required: 'Message is required' })} />
                        {errors.message && <p className="text-danger">{errors.message.message}</p>}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-end">
                <Button variant="secondary" onClick={handleClose} className="mr-2 button-secondary-contact">
                    Close
                </Button>
                <Button
                    className="button-primary-contact"
                    variant="primary"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isLoading} // Disable the button while loading
                >
                    {isLoading ? <Spinner animation="border" size="sm" /> : 'Submit'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ContactModal;
