import { useState } from 'react';
import { updateProfile } from '../../../axiosCalls/auth';

const reminderOnbarod = {
    showProgress: 'true',
    steps: [
        {
            element: '#reminders-search',
            popover: {
                title: 'Search',
                description: 'You can search reminders here'
            }
        },
        {
            element: '#google-calendar',
            popover: {
                title: 'Google Calendar',
                description: 'You can connect google calendar '
            }
        },
        {
            element: '#add-reminder-calendar',
            popover: {
                title: 'Add Reminder',
                description: 'By click this button, you can add reminders'
            }
        }
    ]
};
const servicesOnboard = {
    showProgress: 'true',
    steps: [
        {
            element: '#services-search',
            popover: {
                title: 'Search',
                description: 'You can search services here'
            }
        },
        {
            element: '#expense-detail',
            popover: {
                title: 'Expense',
                description: 'You can see expense detail by click this button'
            }
        },
        {
            element: '#add-purchase-service',
            popover: {
                title: 'Add Purchase & Service',
                description: 'You can add purchases and services here'
            }
        }
    ]
};
const projectOnboard = {
    showProgress: 'true',
    steps: [
        {
            element: '#add-project',
            popover: {
                title: 'Add Project',
                description: 'You can add project by complete the form'
            }
        }
    ]
};
const fileOnboard = {
    showProgress: 'true',
    steps: [
        {
            element: '#search-file',
            popover: {
                title: 'Search',
                description: 'You can search file through'
            }
        },
        {
            element: '#filter-file',
            popover: {
                title: 'Filter',
                description: 'Filter out data by selecting filters'
            }
        },
        {
            element: '#add-file',
            popover: {
                title: 'Add File',
                description: 'You can add a file by filling out the form details'
            }
        }
    ]
};
const propertyReportOnboard = {
    showProgress: 'true',
    steps: [
        {
            element: '#export-print',
            popover: {
                title: 'Export & Print',
                description: 'You can export & print data'
            }
        },
        {
            element: '#property-filter',
            popover: {
                title: 'Property Filter',
                description: 'You can apply all those filters'
            }
        },
        {
            element: '#expense-total',
            popover: {
                title: 'Expense Total',
                description: 'Here is the expense total section which is shown all total expenses'
            }
        },
        {
            element: '#expense-detail',
            popover: {
                title: 'Expense Detail',
                description: 'Here is the expense detail section which is shown all expense detail'
            }
        },
        {
            element: '#task-detail-overview',
            popover: {
                title: 'Task Detail & Overview',
                description: 'Here is the section for task detail & overview'
            }
        },
        {
            element: '#property-warranty',
            popover: {
                title: 'Property Warranty',
                description: 'Shown Property Warranties'
            }
        },
        {
            element: '#project-cost',
            popover: {
                title: 'Project',
                description: 'Shown Project Cost'
            }
        },
        {
            element: '#property-files',
            popover: {
                title: 'Property Files',
                description: 'Shown all property files'
            }
        }
    ]
};
const entireDriver = {
    onPopoverRender: async (popover, { config, state }) => {
        const totalSteps = config.steps.length - 1;
        const activeStep = state.activeIndex;

        const storedUser = localStorage.getItem('user');
        const userData = JSON.parse(storedUser);

        const updatedUser = {
            ...userData,
            meta_data: {
                ...userData.meta_data,
                mainTour: true
            }
        };
        try {
            const response = await updateProfile({
                ...updatedUser
            });
            if (response && response.data) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }
        } catch (error) {}
    },
    showProgress: 'true',
    steps: [
        {
            element: '#add-property',
            popover: {
                title: 'Add Property',
                description: "Before we get started, let's create your first property"
            }
        },
        {
            element: '#home-essentials',
            popover: {
                title: 'Home Essential',
                description: 'Great! Now let’s add some home essentials for your property.',
                side: 'right',
                align: 'start'
            }
        },
        {
            element: '#reminders',
            popover: {
                title: 'Reminders',
                description: 'Get reminders on the go'
            }
        },
        {
            element: '#purchases-services',
            popover: {
                title: 'Purchase and Services',
                description: 'Keep info about all the purchases and services and track future expenses to help meet financial goals.',
                side: 'right',
                align: 'start'
            }
        },
        {
            element: '#projects',
            popover: {
                title: 'Projects',
                description: '',
                side: 'right',
                align: 'start'
            }
        },
        {
            element: '#files',
            popover: {
                title: 'Files',
                description: 'Let’s upload some files related to the property (insurance documents, tax documents, association bylaws, warranties, etc.). Click on ‘Files’ tab to visit a necessary page.',
                side: 'right',
                align: 'start'
            }
        },
        {
            element: '#notification',
            popover: {
                title: 'Notifications',
                description: 'Stay updated with the latest alerts and updates here.',
                side: 'right',
                align: 'start'
            }
        },
        {
            element: '#property-report',
            popover: {
                title: 'Property Report',
                description: 'View and manage property-related reports and details.',
                side: 'right',
                align: 'start'
            }
        },
        {
            element: '#user-settings',
            popover: {
                title: 'User Settings',
                description: 'Customize your preferences and account settings.',
                side: 'right',
                align: 'start'
            }
        },
        {
            element: '#seller-switch',
            popover: {
                title: 'Switch to different role',
                description: 'Change your role based on access',
                side: 'right',
                align: 'start'
            }
        }
    ]
};

const affilateOverview = {
    // onPopoverRender: async (popover, { config, state }) => {},
    showProgress: 'true',
    steps: [
        {
            // id: "account-balance",
            element: '#account-balance',
            popover: {
                title: 'Account Balance Overview',
                description: 'Explore various earnings and performance metrics.'
                // side: "top",
                // align: "center",
            }
        },
        {
            // id: "life-time-earnings",
            element: '#life-time-earnings',
            popover: {
                title: 'Lifetime Earnings',
                description: 'Track your total earnings over time from here.'
                // side: "right",
                // align: "start",
            }
        },
        {
            id: 'ytd-earnings',
            element: '#ytd-earnings',
            popover: {
                title: 'YTD Earnings',
                description: 'Monitor your year-to-date earnings.',
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'giftings-remind',
            element: '#giftings-remind',
            popover: {
                title: 'Gift Remind Earnings',
                description: 'View details about earnings from gift reminders.',
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'referal-code',
            element: '#referal-code',
            popover: {
                title: 'Referral Codes Earnings',
                description: 'Check your earnings through referral codes.',
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'num-of-code-redeemed',
            element: '#num-of-code-redeemed',
            popover: {
                title: 'Number of Codes Redeemed',
                description: 'See how many referral codes have been redeemed.',
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'num-of-subscriptions-gifted',
            element: '#num-of-subscriptions-gifted',
            popover: {
                title: 'Number of Subscriptions Gifted',
                description: 'Track the total gifted subscriptions.',
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'gift-refer-reminders',
            element: '#gift-refer-reminders',
            popover: {
                title: 'Gift or Referral Earnings',
                description: `
      Monitor your earnings from gifts or referrals:<br /><br />
      - <strong>Subscriptions Gifted</strong>: Track the number of subscriptions you've gifted to others and the corresponding rewards you have earned for these actions.<br /><br />
      - <strong>Redeemed Referrals</strong>: See how many of your referral codes have been redeemed by others, and the rewards you've earned from successful referrals.<br /><br />
      - <strong>Referral Code Generation</strong>: Create and manage new referral codes, making it easy for you to share them and track their usage.
    `,
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'my-network',
            element: '#my-network',
            popover: {
                title: 'My Network',
                description: 'View and manage your connections, track interactions, and stay updated on relevant activities within your network.',
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'user-settings',
            element: '#user-settings',
            popover: {
                title: 'User Settings',
                description: 'Customize your preferences, manage account details, and adjust application settings to suit your needs.',
                side: 'right',
                align: 'start'
            }
        },
        {
            id: 'payments',
            element: '#payments',
            popover: {
                title: 'Payments',
                description: 'Set up your wallet and bank information to manage transactions. Affiliates need to configure their Stripe wallet to make their first gift or referral, which can be done during onboarding or before gifting or referring a subscription.',
                side: 'right',
                align: 'start'
            }
        }
    ]
};

export { reminderOnbarod, servicesOnboard, projectOnboard, entireDriver, fileOnboard, propertyReportOnboard, affilateOverview };
