import React, { useRef } from 'react';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { Form } from 'react-bootstrap';

const libraries = ['places'];

const GoogleMapInput = ({ setPlace, setSearchQuery, searchQuery }) => {
    const autocompleteRef = useRef(null); // Ref to store the Autocomplete instance

    const handlePlaceSelect = () => {
        const google_place = autocompleteRef.current.getPlace();
        if (google_place) {
            setPlace(google_place);
        }
    };

    return (
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY} libraries={libraries}>
            <Autocomplete className="w-100" onLoad={(autocompleteInstance) => (autocompleteRef.current = autocompleteInstance)} onPlaceChanged={handlePlaceSelect}>
                <Form.Control
                    id="address"
                    type="text"
                    className="hide-b-input"
                    placeholder="Enter address"
                    value={searchQuery}
                    onChange={(e) => {
                        setSearchQuery(e.target.value);
                    }}
                />
            </Autocomplete>
        </LoadScript>
    );
};

export default GoogleMapInput;
