import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import swtichIcon from '../assets/icons/refresh-cw.svg';
import walletIcon from '../assets/icons/walletIcon.svg';
import stripeIcon from '../assets/icons/stripeIcon.svg';
import walletIconWhite from '../assets/icons/walletIconWhite.svg';
import { fetchWalletBalance, getAccountIdFromCode } from '../../axiosCalls/seller';
import { toast } from 'react-toastify';

const SellerPayments = () => {
    const [balance, setBalance] = useState(0);
    const [searchParams] = useSearchParams(); // Get query parameters

    useEffect(() => {
        // Fetch wallet balance on component load
        const walletBalance = async () => {
            const res = await fetchWalletBalance();
            setBalance(res.data.balance);
        };

        walletBalance();
    }, []);

    const exchangeCodeForAccountId = async (code) => {
        const data = {
            code: code
        };
        const res = await getAccountIdFromCode(data);
        return res.data.accountId;
    };
    useEffect(() => {
        const code = searchParams.get('code'); // Extract 'code' from URL

        if (code) {
            exchangeCodeForAccountId(code);
        } else {
            // toast.error('No authorization code found');
        }
    }, [searchParams]);

    const currentURL = window.location.origin; // Gets the current origin (e.g., http://localhost:3000 or production URL)
    const redirectURI = `${currentURL}/seller/seller-payment`; // Construct dynamic redirect URI

    const stripeAuthURL = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${redirectURI}`;
    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="col-xl-12 grid-margin stretch-card flex-column">
                    <div className="">
                        <div className="">
                            <div className="row">
                                <div className="col-lg-5 col-md-12 col-sm-12">
                                    <h5 className="mb-2 text-titlecase mb-4">Payments</h5>
                                </div>
                            </div>
                        </div>
                        <div className="seller-payment-page mb-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="seller-payment-inner">
                                        <div className="seller-payment-inner-left">
                                            <div className="seller-payment-inner-left-amount mb-3">
                                                <img src={walletIcon} alt="walletIcon" />
                                                <span>${balance}</span>
                                            </div>
                                            <div className="seller-payment-inner-left-avil-bal mb-3">
                                                <label>Available Balance:</label>
                                                <span>Balance available for withdrawal</span>
                                            </div>
                                            <div className="seller-payment-inner-left-avil-bal mb-3">
                                                <label>Withdrawn to date:</label>
                                                <span>${balance}</span>
                                            </div>
                                            <div className="seller-payment-inner-left-stripeBtn">
                                                <Link to={stripeAuthURL}>
                                                    <img src={stripeIcon} /> <span> Connect Your Stripe Wallet</span>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="seller-payment-inner-right">
                                            <div className="withdarw-bal-button">
                                                <img src={walletIconWhite} alt="walletIconWhite" />
                                                <span>Withdraw Balance</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="seller-payment-page">
                            <div className="row">
                                <div className="col-md-12">

                                    <div className='seller-payment-inner'>
                                        <div className='seller-payment-inner-left'>
                                            <div className='seller-payment-inner-left-amount mb-3'>
                                                <img src={walletIcon} alt='walletIcon' />
                                                <span>1099 Tax Form</span>
                                            </div>
                                            <div className='seller-payment-inner-left-avil-bal '>
                                                <label>Eligibility</label>
                                                <span>The other piece is the 1099 tax form for Affiliates that reach $600+ dollars in earnings over the course of a year</span>
                                            </div>



                                        </div>
                                        <div className='seller-payment-inner-right'>

                                            <Link to='/seller/seller-affiliate-tax-requirements' className='withdarw-bal-button'>
                                                <img src={walletIconWhite} alt='walletIconWhite' />
                                                <span>Submit Form</span>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SellerPayments;
