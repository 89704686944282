import React from 'react';
import { axiosInstance } from './axiosInstance';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// API call to log in the user
export const loginApi = async (email, password) => {
    let res;
    try {
        const data = JSON.stringify({
            username_or_email: email,
            password: password
        });
        res = await axiosInstance.post('/login', data);
    } catch (error) {}
    return res ? res : '';
};

// API call to get the list of plans
export const plansList = async () => {
    let res;
    try {
        res = await axiosInstance.get('/get-plans');
    } catch (error) {}
    return res ? res : '';
};
const handleLogin = async (email, password) => {
    if (!email || !password) {
        toast.error('Please fill in all fields.');
        return;
    }

    try {
        const response = await loginApi(email, password);

        if (response && response.data) {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem('user', JSON.stringify(response.data));

            const user = response.data;
            // Determine the redirect URL based on user role
            let redirectUrl = '/dashboard'; // Default redirect URL
            if (user.role === 'homeowner') {
                redirectUrl = '/dashboard'; // Replace with your desired URL for affiliates
            } else if (user.role === 'affiliate') {
                redirectUrl = '/seller/overview'; // Replace with your desired URL for admins
            } else if (user.role === 'homeowner_affiliate') {
                redirectUrl = '/dashboard'; // Replace with your desired URL for regular users
            }

            window.location.href = redirectUrl;
        } else {
        }
    } catch (error) {
        toast.error('An error occurred. Please try again.');
    }
};
// API call to sign up a new user
export const signUpApis = async (data) => {
    let res;
    try {
        res = await axiosInstance.post('/register', data);
        if (res && res.data) {
            handleLogin(data.email, data.password);
        }
    } catch (error) {}
    return res ? res : '';
};

// API call to initiate forgot password process
export const forgotPassword = async (data) => {
    let res;
    try {
        res = await axiosInstance.post('/forgot-password', data);
    } catch (error) {}
    return res ? res : '';
};

// API call to reset the user's password
export const resetPassword = async (data) => {
    let res;
    try {
        res = await axiosInstance.post('/reset-password', data);
    } catch (error) {}
    return res ? res : '';
};

// API call to update user profile
export const updateProfile = async (data, newPassword) => {
    let res;
    data['password'] = newPassword;
    try {
        res = await axiosInstance.post('/update-profile', data);
        // toast.success("Your profile has been updated successfully");
    } catch (error) {}
    return res ? res : '';
};

export const verifyPassword = async (data) => {
    let res;
    try {
        res = await axiosInstance.post('/verify-password', data);
    } catch (error) {}
    return res ? res : '';
};
export const getPlanById = async (planID) => {
    let res;
    try {
        res = await axiosInstance.get(`/get-plan/${planID}`);
    } catch (error) {}
    return res ? res : '';
};

// login with google
export const loginWithGoogle = async (idToken) => {
    let res;
    try {
        res = await axiosInstance.post('/google-login', idToken);
    } catch (error) {}
    return res ? res : '';
};
