export const LOADING = 'LOADING';

export const ALL_RECORDS = 'ALL_RECORDS';
export const ADD_RECORD = 'ADD_RECORD';
export const EDIT_RECORD = 'EDIT_RECORD';
export const DELETE_RECORD = 'DELETE_RECORD';

const types = {
    ALL_RECORDS,
    ADD_RECORD,
    EDIT_RECORD,
    DELETE_RECORD,
    LOADING
};
export default types;
