import React from 'react';
import { Navigate } from 'react-router-dom';

const AuthLayouts = (props) => {
    const { childComp } = props;

    const isAuthenticated = localStorage.getItem('token'); // Adjust the condition as necessary
    if (isAuthenticated) {
        return <Navigate to="/dashboard" />;
    }

    return (
        <div>
            <div className="container-scroller">
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="content-wrapper d-flex align-items-center auth px-0">
                        <div className="row w-100 mx-0">
                            <div className="col-lg-6 col-md-8 col-sm-12 mx-auto auth-col">{childComp}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthLayouts;
