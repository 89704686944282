import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ConfirmationModal = ({ show, onHide, onConfirm, title, body, loading }) => {
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title className="mx-2">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-left">{body}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button disabled={loading} variant="danger" onClick={onConfirm}>
                    {loading ? 'Processing...' : 'Confirm Delete'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

// PropTypes for type checking
ConfirmationModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string,
    body: PropTypes.string
};

ConfirmationModal.defaultProps = {
    title: 'Confirm Action',
    body: 'Are you sure you want to perform this action?'
};

export default ConfirmationModal;
