import React, { useEffect, useState } from 'react';
import './style.css';
import MainSidebar from '../sidebar/mainSidebar';
import Footer from '../footer';
import HeaderMain from '../header';
import { Link, Navigate } from 'react-router-dom';
import swtichIcon from '../../../seller/assets/icons/refresh-cw.svg';
import ContactModal from '../modals/ContactModal';
import { ReactComponent as ContactIcon } from '../../asstes/dash-icons/contact-solid-new.svg';

const DashLayouts = (props) => {
    const { childComp } = props;
    const [openSideBar, setOpenSideBar] = useState('close');
    const [showModal, setShowModal] = useState(false);
    const [isAffiliate, setIsAffiliate] = useState(false);

    const handleShow = () => setShowModal(true); // Open modal
    const handleClose = () => setShowModal(false); // Close modal

    useEffect(() => {
        const user = localStorage.getItem('user');

        try {
            if (user) {
                const parsedUser = JSON.parse(user);
                setIsAffiliate(parsedUser.role === 'homeowner_affiliate');
            } else {
                setIsAffiliate(false);
            }
        } catch (error) {
            console.error('Error parsing user data from localStorage:', error);
            setIsAffiliate(false);
        }
    }, []);

    const handlerSideBar = (val) => {
        setOpenSideBar(val);
    };
    const handlercolseSidebar = () => {
        setOpenSideBar('close');
        document.body.classList.remove('no-scroll');
    };
    const handlerCloseSidebarFromClick = (val) => {
        setOpenSideBar(val);
    };

    const token = localStorage.getItem('token');
    if (!token) {
        // Not authenticated
        return <Navigate to="/login" />;
    }

    return (
        <div className="container-scroller">
            <HeaderMain stateSide={openSideBar} handlerSideBar={handlerSideBar} />
            <div className="page-body-wrapper">
                {openSideBar === 'open' ? <div onClick={handlercolseSidebar} data-toggle="offcanvas" className="mainSidebar sidenav-overlay" /> : ''}
                <MainSidebar stateSide={openSideBar} handlerCloseSidebarFromClick={handlerCloseSidebarFromClick} />
                <div className="main-panel">
                    {isAffiliate ? (
                        <div className="reminder-top-button homeOwnerRole">
                            <Link to="/seller/overview" className="seller-switch-role">
                                <img src={swtichIcon} alt="switch-icon" /> Switch to <b>Affiliate Role</b>
                            </Link>
                            <div className="contact_us" onClick={handleShow}>
                                <ContactIcon />
                            </div>
                        </div>
                    ) : (
                        <div className="reminder-top-button homeOwnerRole">
                            <div className="contact_us" onClick={handleShow}>
                                <ContactIcon />
                            </div>
                        </div>
                    )}

                    {childComp}
                </div>
            </div>
            <ContactModal showModal={showModal} handleClose={handleClose} />
            <Footer />
        </div>
    );
};

export default DashLayouts;
