import { toast } from 'react-toastify';
import { axiosInstance } from './axiosInstance';
import { isPropertyExist } from '../user-dashboard/utils/helper';

export const getProperties = async () => {
    let res;
    try {
        res = await axiosInstance.get('/get-properties');
    } catch (error) {}
    return res ? res : '';
};
export const deleteProperties = async (propId) => {
    let res;
    try {
        res = await axiosInstance.delete('/delete-property/' + propId);
    } catch (error) {}
    return res ? res : '';
};

export const getPropertyById = async (id) => {
    let res;
    try {
        res = await axiosInstance.get(`/get-property/${id}`);
    } catch (error) {}
    return res ? res : '';
};

export const getStates = async () => {
    let res;
    try {
        res = await axiosInstance.get('/states');
    } catch (error) {}
    return res ? res : '';
};
export const getTaxonomyById = (taxonomyType, id) => {
    return axiosInstance.get(`/${taxonomyType}/${id}`);
};

export const getPropertyTypes = async () => {
    let res;
    try {
        res = await axiosInstance.get('/property-type');
    } catch (error) {}
    return res ? res : '';
};

export const getPropertyCategory = async () => {
    let res;
    try {
        res = await axiosInstance.get('/property-category');
    } catch (error) {}
    return res ? res : '';
};

export const addPropertyData = async (data) => {
    let res;
    try {
        res = await axiosInstance.post('/add-property', data);
    } catch (error) {}
    return res ? res : '';
};
export const updatePropertyData = async (data, id) => {
    let res;
    try {
        res = await axiosInstance.post(`/update-property/${id}`, data);
    } catch (error) {}
    return res ? res : '';
};
export const getSuggestedReminders = async () => {
    let res;
    try {
        res = await axiosInstance.get('/get-suggested-reminders/');
    } catch (error) {}
    return res ? res : '';
};
export const getCustomReminders = async () => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

        res = await axiosInstance.get(`/get-custom-reminders/?property_id=${propertyData.id}`);
    } catch (error) {}
    return res ? res : '';
};

export const listOfFrequencies = async () => {
    let res;
    try {
        res = await axiosInstance.get('/frequency-terms/');
    } catch (error) {}
    return res ? res : '';
};

export const addNotificationData = async (data, post_id) => {
    let res;
    const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

    data['property_id'] = propertyData.id;
    data['reminder_id'] = post_id;
    try {
        res = await axiosInstance.post('/add-event', data);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};

export const addCustomReminder = async (data) => {
    let res;
    const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

    data['property_id'] = propertyData.id;
    try {
        res = await axiosInstance.post('/add-reminder', data);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};
export const updateCustomReminder = async (data) => {
    let res;
    try {
        res = await axiosInstance.put(`/update-reminder/${data.reminder_id}`, data);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};

export const fetchHomeEssentials = async () => {
    let res;
    const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

    try {
        res = await axiosInstance.get(`/home-essentials?property_id=${propertyData.id}`);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};
export const get_event_lists = async () => {
    let res;
    const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

    try {
        res = await axiosInstance.get(`/current-week-events?property_id=${propertyData.id}`);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};
export const get_all_event_lists = async () => {
    let res;
    const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

    try {
        res = await axiosInstance.get(`/event-lists?property_id=${propertyData.id}`);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};

export const get_event_by_id = async (data) => {
    let res;
    const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

    try {
        res = await axiosInstance.get(`/event/${data}`);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};
export const update_event_by_id = async (id, data) => {
    let res;
    const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

    try {
        res = await axiosInstance.put(`/event-update/${id}`, data);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};

export const get_user_google_token = async (data) => {
    let res;

    try {
        res = await axiosInstance.get(`/google-authenticate/?code=${data}`);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};
export const refresh_token_if_needed = async (refreshToken) => {
    let res;

    try {
        res = await axiosInstance.post('/refresh-token/');
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};
export const disconnect_google_account = async (refreshToken) => {
    let res;

    try {
        res = await axiosInstance.post('/google-disconnect/');
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};
export const delete_notification = async (eventId) => {
    let res;

    try {
        res = await axiosInstance.delete(`/event/${eventId}`);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};
export const get_home_essential_types = async (eventId) => {
    let res;

    try {
        res = await axiosInstance.get('/home-essential-types/');
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};
export const getHomeEsstentialReminders = async (type) => {
    let res;
    try {
        if (type == 'homeEssential') {
            res = await axiosInstance.get('/get-reminders/');
        } else if (type == 'custom') {
            const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));

            res = await axiosInstance.get(`/get-custom-reminders/?property_id=${propertyData.id}`);
        }
    } catch (error) {}
    return res ? res : '';
};
export const getHomeEsstentialRemindersByType = async (selectedType) => {
    let res;
    try {
        res = await axiosInstance.get(`/home-essential-reminders?home-type=${selectedType}`);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};

export const addHomeEssential = async (data) => {
    let res;
    try {
        if (isPropertyExist()) {
            const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
            data.append('property_id', propertyData.id);
            res = await axiosInstance.post('/add-home-essential/', data);
        }
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};
export const updateHomeEssential = async (data, id) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        data.append('property_id', propertyData.id);

        res = await axiosInstance.post(`/update-home-essential/${id}`, data);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};
export const getReminderById = async (id) => {
    let res;
    try {
        res = await axiosInstance.get(`/get-reminder/${id}`);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};
export const getHomeEsstentialList = async (id) => {
    let res;
    try {
        if (id) {
            const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
            res = await axiosInstance.get(`/home-essentials/${id}/?property_id=${propertyData.id}`);
        } else {
            const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
            res = await axiosInstance.get(`/home-essentials/?property_id=${propertyData.id}`);
        }
    } catch (error) {}
    return res ? res : '';
};

export const getProjectsList = async () => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        res = await axiosInstance.get(`/get-projects/?property_id=${propertyData.id}`);
    } catch (error) {}
    return res ? res : '';
};

// purchase item
export const AddPurchaseItem = async (data) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        data.append('property_id', propertyData.id);
        res = await axiosInstance.post('/add-purchase', data);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};

// delete purchase
export const deletePurchaseItemAPI = async (id) => {
    let res;
    try {
        res = await axiosInstance.delete(`/delete-purchase/${id}`);
    } catch (error) {}
    return res ? res : '';
};
export const getPurchaseList = async () => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        res = await axiosInstance.get(`/get-purchases/?property_id=${propertyData.id}`);
    } catch (error) {}
    return res ? res : '';
};
export const getPurchaseItem = async (id) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        res = await axiosInstance.get(`/get-purchase/${id}`);
    } catch (error) {}
    return res ? res : '';
};
export const updatePurchaseItem = async (id, data) => {
    let res;
    try {
        res = await axiosInstance.post(`/update-purchase/${id}`, data);
    } catch (error) {}
    return res ? res : '';
};
export const updateServiceItem = async (id, data) => {
    let res;
    try {
        res = await axiosInstance.post(`/update-service/${id}`, data);
    } catch (error) {}
    return res ? res : '';
};

// delete service
export const deleteServiceItemAPI = async (id) => {
    let res;
    try {
        res = await axiosInstance.delete(`/delete-service/${id}`);
    } catch (error) {}
    return res ? res : '';
};

// services item

export const getServiceList = async () => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        res = await axiosInstance.get(`/get-services/?property_id=${propertyData.id}`);
    } catch (error) {}
    return res ? res : '';
};
export const getServiceItem = async (id) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        res = await axiosInstance.get(`/get-service/${id}`);
    } catch (error) {}
    return res ? res : '';
};

export const AddServiceItem = async (data) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        data.append('property_id', propertyData.id);
        res = await axiosInstance.post('/add-service', data);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};

// project

export const addProjectApis = async (data) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty')) || {};
        data.append('property_id', propertyData.id);
        res = await axiosInstance.post('/add-project', data);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};

export const updateProjectApis = async (data, id) => {
    let res;
    try {
        res = await axiosInstance.post(`/update-project/${id}`, data);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};

// delete project api
export const deleteProjectApis = async (id) => {
    let res;
    try {
        res = await axiosInstance.delete(`/delete-project/${id}`);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};

// get project by id
export const getProjectById = async (id) => {
    let res;
    try {
        res = await axiosInstance.get(`/get-project/${id}`);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};

export const addFileApis = async (data) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        data.append('property_id', propertyData.id);
        res = await axiosInstance.post('/add-file', data);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};
export const updateFileApis = async (data, id) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        data.append('property_id', propertyData.id);
        res = await axiosInstance.post(`/update-file/${id}`, data);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};
export const getFilesList = async () => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        res = await axiosInstance.get(`/get-files?property_id=${propertyData.id}`);
    } catch (error) {}
    return res ? res : '';
};
export const deleteFile = async (id) => {
    let res;
    try {
        res = await axiosInstance.delete(`/delete-file/${id}`);
    } catch (error) {}
    return res ? res : '';
};
export const searchPostsList = async (query) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        res = await axiosInstance.get(`/search-posts?title=${encodeURIComponent(query)}&property_id=${propertyData.id}`);
    } catch (error) {}
    return res ? res : '';
};
export const uploadFile = async (data) => {
    const url = process.env.REACT_APP_DEV_NODE_URL + 'media/upload';
    const formBody = new FormData();
    Object.entries(data).forEach(([key, value]) => {
        formBody.append(key, value);
    });
    return await axiosInstance.post(url, formBody);
};

// fetch property
export const getPropertyFromGoogle = async (query) => {
    let res;
    try {
        res = await axiosInstance.get(`https://maps.googleapis.com/maps/api/place/textsearch/json?query=${encodeURIComponent(query)}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`);
    } catch (error) {}
    return res ? res : '';
};

// create-subscription
export const create_subscription = async (data) => {
    let res;
    try {
        res = await axiosInstance.post('/create-subscription', data);
    } catch (error) {}
    return res ? res : '';
};

// delet home essesnitals
export const deleteHomeEssentials = async (id) => {
    try {
        const response = await axiosInstance.delete(`/delete-home-essential/${id}`);
        return response;
    } catch (error) {
        console.error('Error deleting home essentials:', error);
        return null;
    }
};

export const contactUs = async (data) => {
    try {
        const response = await axiosInstance.post('/contact-form', data);
        return response;
    } catch (error) {
        console.error('Error submitting contact form:', error);
        return null;
    }
};

export const deleteAllNotificationsByProperty = async () => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        if (!propertyData.id) {
            // toast message
            toast.error('Please select/add a property first');
            return false;
        }
        res = await axiosInstance.delete(`/events/property/${propertyData.id}`);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};
export const deleteNotificationsById = async (id) => {
    let res;
    try {
        res = await axiosInstance.delete(`/event/${id}`);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};

export const updateNotificationsStatusById = async (id, data) => {
    let res;
    // status_task
    try {
        res = await axiosInstance.put(`/event-task-status-update/${id}`, data);
    } catch (error) {
        res = error;
    }
    return res ? res : '';
};

export const getPropertyReportData = async (query) => {
    let res;
    try {
        const propertyData = JSON.parse(localStorage.getItem('selectedProperty'));
        if (!propertyData.id) {
            return false;
        }
        // Use query parameters in the GET request
        res = await axiosInstance.get('/property-report', {
            params: { ...query, property_id: propertyData.id } // Pass the query object directly
        });
    } catch (error) {
        res = error.response || error; // Handle the error response properly
    }
    return res?.data || ''; // Return only the response data or an empty string
};
