import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Select } from './Select';
import LinkReminders from './linkReminders';
import pdfIcon from '../../asstes/dash-icons/pdfIcon.svg';
import { addProjectApis, fetchHomeEssentials, getPurchaseList, getServiceList, updateProjectApis } from '../../../axiosCalls/userdashboard';
import ScrollToError from '../input/ScrollToError';
import Loader from '../loader/Loader';
import { toast } from 'react-toastify';

const ProjectsSideNavNew = ({ fetchProjectById, handleGetProjectList, handleClose, project }) => {
    const [homeEssentials, setHomeEssentials] = useState([]);
    const [purchaseList, setPurchaseList] = useState([]);
    const [servicesList, setServicesList] = useState([]);

    useEffect(() => {
        const fetchEssentials = async () => {
            try {
                const response = await fetchHomeEssentials();
                const optionsData = response.data?.items?.map((item) => ({
                    label: `${item.type} - ${item.model_number}`,
                    value: item.id
                }));
                setHomeEssentials(optionsData);
            } catch (error) {
                console.error('Error fetching home essentials:', error);
            }
        };

        const fetchPurchases = async () => {
            try {
                const response = await getPurchaseList();
                const projectOptions = response.data?.map((p) => ({
                    value: p.id,
                    label: `${p.product_name}-(Date: ${p.purchase_date})-(Amount: (${p?.amount} $)`
                }));
                setPurchaseList(projectOptions);
            } catch (error) {
                console.error('Error fetching projects:', error);
            }
        };

        const fetchServices = async () => {
            try {
                const response = await getServiceList();
                const projectOptions = response.data?.map((p) => ({
                    value: p.id,
                    label: `${p.vendor}-(Date: ${p?.service_date})-(Amount: ${p.amount} $)`
                }));
                setServicesList(projectOptions);
            } catch (error) {
                console.error('Error fetching services:', error);
            }
        };

        fetchServices();
        fetchPurchases();
        fetchEssentials();
    }, []);

    // Function to get the appropriate icon for files
    const getFileIcon = (file) => {
        if (file.type === 'application/pdf') {
            return pdfIcon; // PDF icon
        } else {
            return URL.createObjectURL(file); // Image preview
        }
    };

    const validationSchema = Yup.object().shape({
        project_name: Yup.string().required('Project Name is required'),
        start_date: Yup.date().required('Start Date is required')
    });
    const handleSubmit = async (values, { setSubmitting }) => {
        const { project_name, start_date, end_date, notes, linked_home_essentials, linked_services, linked_purchases, files, images } = values;
        // Check for required fields
        setSubmitting(true);

        try {
            const data = new FormData();
            data.append('project_name', project_name || '');
            data.append('start_date', start_date || '');
            data.append('end_date', end_date || '');
            data.append('notes', notes || '');
            data.append('linked_home_essentials', JSON.stringify(linked_home_essentials));
            data.append('linked_services', JSON.stringify(linked_services));
            data.append('linked_purchases', JSON.stringify(linked_purchases));
            files?.forEach((file) => data.append('files[]', file));
            images?.forEach((image) => data.append('images[]', image));
            const res = values?.ID ? await updateProjectApis(data, values?.ID) : await addProjectApis(data);
            if (res && res.status === 200) {
                handleClose();
                if (handleGetProjectList) await handleGetProjectList();
                if (fetchProjectById) await fetchProjectById();
                toast.success(res.data.message);
            }
        } catch (error) {
            console.error('Error submitting project:', error);
            toast.error('There was an error submitting the project. Please try again.');
        } finally {
            setSubmitting(false);
        }
    };
    const [initialValues, setInitialValues] = useState({
        project_name: '',
        start_date: '',
        end_date: '',
        files: [],
        linked_home_essentials: [],
        linked_services: [],
        linked_purchases: []
    });
    useEffect(() => {
        if (project) {
            const { title, start_date, end_date, files } = project;
            const linked_home_essentials = (project.linked_home_essentials && JSON.parse(project?.linked_home_essentials)) || [];
            const linked_services = (project.linked_services && JSON.parse(project?.linked_services)) || [];
            const linked_purchases = (project.linked_purchases && JSON.parse(project?.linked_purchases)) || [];
            setInitialValues({
                ...project,
                project_name: title,
                start_date,
                end_date,
                files: files,
                linked_home_essentials: linked_home_essentials?.map((item) => ({
                    label: `${item.label}`,
                    value: item.value
                })),
                linked_services: linked_services?.map((item) => ({
                    label: item.label,
                    value: item.value
                })),
                linked_purchases: linked_purchases?.map((item) => ({
                    label: item.label,
                    value: item.value
                }))
            });
        }
    }, [project]);

    return (
        <div className="side-nav">
            <div className="side-header">
                <h3>{initialValues?.ID ? 'Edit Project' : 'Add Project'}</h3>
            </div>
            <div className="side-body">
                <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                    {({ isSubmitting, errors, values, touched, handleChange, setFieldValue }) => (
                        <FormikForm>
                            <ScrollToError />
                            <Row className="mb-3">
                                <Form.Group controlId="projectName" as={Col} md="12" className="mb-3">
                                    <Form.Label>
                                        Project Name <span className="requiredStar">*</span>
                                    </Form.Label>
                                    <Form.Control type="text" name="project_name" placeholder="Name" value={values.project_name} onChange={handleChange} isInvalid={touched.project_name && errors.project_name} />
                                    <Form.Control.Feedback type="invalid">{errors.project_name}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="mb-3">
                                    <Form.Label>
                                        Start Date <span className="requiredStar">*</span>
                                    </Form.Label>
                                    <Form.Control type="date" name="start_date" value={values.start_date} isInvalid={touched?.start_date && errors.start_date} onChange={handleChange} />
                                    <Form.Control.Feedback type="invalid">{errors?.start_date}</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="mb-3">
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control type="date" name="end_date" value={values.end_date} onChange={handleChange} />
                                </Form.Group>

                                {/* Files Upload */}
                                <Form.Group md="12" className="mb-3">
                                    <div className="files-choosen">
                                        <div className="title">Files</div>
                                        <div className="button">
                                            <div className="outline-yellow-button">
                                                Add file
                                                <input
                                                    type="file"
                                                    name="files"
                                                    multiple
                                                    accept=".png,.pdf,.jpg,.jpeg"
                                                    className="chooeseFile"
                                                    onChange={(event) => {
                                                        const files = Array.from(event.target.files);
                                                        setFieldValue('files', [...values.files, ...files]);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="listOfFiles">
                                        {values?.files?.map((file, index) => (
                                            <div className="image-choosed" key={index}>
                                                <a href={URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer">
                                                    <img src={getFileIcon(file)} alt="File preview" />
                                                </a>
                                                <button
                                                    onClick={() => {
                                                        const updatedFiles = [...values.files];
                                                        updatedFiles.splice(index, 1);
                                                        setFieldValue('files', updatedFiles);
                                                    }}
                                                    className="image-choosed-crossed"
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </Form.Group>

                                {/* Images Upload */}
                                <Form.Group md="12" className="mb-3">
                                    <div className="files-choosen">
                                        <div className="title">Images</div>
                                        <div className="button">
                                            <div className="outline-yellow-button">
                                                Add image
                                                <input
                                                    type="file"
                                                    name="images"
                                                    multiple
                                                    accept=".png,.jpg,.jpeg"
                                                    className="chooeseFile"
                                                    onChange={(e) => {
                                                        const files = Array.from(e.target.files);
                                                        const images = files.filter((file) => file.type.startsWith('image/'));
                                                        setFieldValue('images', [...values.images, ...images]);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="listOfImages">
                                        {values?.images?.map((image, index) => (
                                            <div className="image-choosed" key={index}>
                                                <a href={URL.createObjectURL(image)} target="_blank" rel="noopener noreferrer">
                                                    <img src={URL.createObjectURL(image)} alt="Image preview" />
                                                </a>
                                                <button
                                                    onClick={() => {
                                                        const updatedImages = [...values.images];
                                                        updatedImages.splice(index, 1);
                                                        setFieldValue('images', updatedImages);
                                                    }}
                                                    className="image-choosed-crossed"
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </Form.Group>

                                {/* Linked Purchases */}
                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label>Linked Purchases</Form.Label>
                                    <Select
                                        multiple
                                        options={purchaseList}
                                        value={values?.linked_purchases}
                                        onChange={(selected) => {
                                            setFieldValue('linked_purchases', selected);
                                        }}
                                    />
                                </Form.Group>

                                {/* Linked Services */}
                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label>Linked Services</Form.Label>
                                    <Select
                                        multiple
                                        options={servicesList}
                                        value={values?.linked_services}
                                        onChange={(selected) => {
                                            setFieldValue('linked_services', selected);
                                        }}
                                    />
                                </Form.Group>

                                {/* Linked Home Essentials */}
                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label>Linked Home Essentials</Form.Label>
                                    <Select
                                        multiple
                                        options={homeEssentials}
                                        value={values?.linked_home_essentials}
                                        onChange={(selected) => {
                                            setFieldValue('linked_home_essentials', selected);
                                        }}
                                    />
                                </Form.Group>

                                {/* Notes */}
                                <Form.Group as={Col} className="mb-3">
                                    <Form.Label>Notes</Form.Label>
                                    <Form.Control as="textarea" name="notes" rows={3} placeholder="Type here..." value={values.notes} onChange={handleChange} />
                                </Form.Group>
                            </Row>
                            <div className="off-canvas-buttons form-actions d-flex justify-content-between gap-2">
                                <Button
                                    className="button-cancel"
                                    onClick={() => {
                                        handleClose();
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button type="submit" className="button-primary">
                                    {isSubmitting ? <Loader /> : initialValues?.ID ? 'Update' : 'Save'}
                                </Button>
                            </div>
                        </FormikForm>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default ProjectsSideNavNew;
