import './App.css';
import CreateRoutes from './routes';
// boostrap
import 'bootstrap/dist/css/bootstrap.min.css';
import './user-dashboard/asstes/styles/responsiveness.css';
import './seller/assets/seller-responsiveness.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import 'driver.js/dist/driver.css';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Load Stripe instance
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBKEY);

function App() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Extract token from URL parameters if available
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        if (token) {
            try {
                // Decode the token using jwt-decode
                const decodedPayload = JSON.parse(atob(token)); // Decode Base64

                // Store the token in localStorage
                localStorage.setItem('user', JSON.stringify(decodedPayload.data));
                localStorage.setItem('token', decodedPayload.data.token);
                navigate('/dashboard');
            } catch (error) {}
        }
    }, [location]);
    return (
        <div className="App">
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

            <ToastContainer />
            <Elements stripe={stripePromise}>
                <CreateRoutes />
            </Elements>
        </div>
    );
}

export default App;
