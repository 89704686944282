import React from 'react';

const HomeEssentials = () => {
    return (
        <div className="col-md-12 mb-3" id="project-cost">
            <label className="reportSectiontitile">Home Essentials</label>
            <div className="recentPurcheseTable projectTableReports">Home Essentials</div>
        </div>
    );
};

export default HomeEssentials;
